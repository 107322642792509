import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import Button from "components/base/Button";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import SearchBox from "components/common/SearchBox";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { ChangeEvent } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import {
  fabricantesBreadcrumbItems,
  FabricanteTableType,
} from "data/fabricantes";
import FabricanteTable, {
  fabricanteTableColumns,
} from "components/tables/FabricanteTable";

const fetchFabricantes = async () => {
  const { data } = await axios.get("/api/fabricante");
  return data;
};

const Fabricantes = () => {
  const dataQuery = useQuery<FabricanteTableType[]>({
    queryKey: ["fabricantes"],
    queryFn: fetchFabricantes,
    placeholderData: keepPreviousData,
  });

  const table = useReactTable({
    data: dataQuery.data ?? [],
    columns: fabricanteTableColumns,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  if (dataQuery.isLoading)
    return (
      <Spinner animation="border" role="status" className="d-block mx-auto">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <PageBreadcrumb items={fabricantesBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">Fabricantes</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox
                placeholder="Pesquisar fabricante"
                onChange={handleSearchInputChange}
              />
              <div className="ms-xxl-auto">
                <Button
                  variant="primary"
                  as={Link}
                  to="/retaguarda/cadastros/fabricantes/novo"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Cadastrar
                </Button>
              </div>
            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <FabricanteTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Fabricantes;
