import AppProvider from "providers/AppProvider";
import BreakpointsProvider from "providers/BreakpointsProvider";
import SettingsPanelProvider from "providers/SettingsPanelProvider";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import { router } from "Routes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const client = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <AppProvider>
        <SettingsPanelProvider>
          <BreakpointsProvider>
            <RouterProvider router={router} />
          </BreakpointsProvider>
        </SettingsPanelProvider>
      </AppProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
