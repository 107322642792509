import { PageBreadcrumbItem } from "components/common/PageBreadcrumb";

export type DepartamentoType = {
  id: number;
  codigo: number;
  descricao: string;
};

export type DepartamentoEmpresaType = {
  idEmpresa: number;
  desconto: number;
};

export type CriarDepartamentoDtoType = {
  descricao: string;
  empresas: DepartamentoEmpresaType[];
};

export type AlterarDepartamentoDtoType = {
  codigo: number;
  descricao: string;
  empresas: DepartamentoEmpresaType[];
};

export type DepartamentoTableType = {
  id: number;
  codigo: number;
  descricao: string;
};

export const departamentosBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: "Retaguarda",
    url: "#!",
  },
  {
    label: "Cadastros",
    url: "#!",
  },
  {
    label: "Departamentos",
    active: true,
  },
];

export const departamentoBreadcrumbItems = (
  codigo?: number
): PageBreadcrumbItem[] => [
  {
    label: "Retaguarda",
    url: "#!",
  },
  {
    label: "Cadastros",
    url: "#!",
  },
  {
    label: "Departamento",
    url: "/retaguarda/cadastros/departamentos",
  },
  {
    label: codigo?.toString() ?? "Novo",
    active: true,
  },
];
