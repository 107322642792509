import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import Button from "components/base/Button";
import Dropzone from "components/base/Dropzone";
import { useRef, useState } from "react";
import { Col, Form, Modal } from "react-bootstrap";
import Input from "react-select/dist/declarations/src/components/Input";
import { StylesProps } from "react-select/dist/declarations/src/styles";

const ImportModal = ({
  onConfirm,
  onCancel,
  isShow,
}: {
  onConfirm: (d: any) => void;
  onCancel: () => void;
  isShow: boolean;
}) => {
  const [files, setFiles] = useState<any>();
  const [chave, setChave] = useState<string>("");
  const cancelTokenSource = useRef<any>(null); // Usando ref para manter a referência do cancel token

  const mutation = useMutation({
    mutationFn: async ({ chave, files }: { chave: string; files: any }) => {
      if (chave) {
        const { data } = await axios.post(
          `/api/entradaDeNota/importar/${chave}`
        );
        return data;
      }

      const CancelToken = axios.CancelToken;
      cancelTokenSource.current = CancelToken.source(); // Armazena o source

      const formData = new FormData();

      files.forEach((file: any) => {
        formData.append("files", file);
      });

      const { data } = await axios.post(
        "/api/entradaDeNota/importar",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          cancelToken: cancelTokenSource.current.token, // Usa o token armazenado
        }
      );

      return data;
    },
    onSuccess: () => {
      setFiles(undefined);
      onConfirm({});
    },
    onError: () => {
      // Lidar com erro, se necessário
    },
  });

  const handleExit = () => {
    setFiles(undefined);

    // Cancela a requisição se estiver em andamento
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Requisição cancelada pelo usuário.");
    }

    onCancel();
  };

  return (
    <Modal
      show={isShow}
      onHide={handleExit}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>Importar nota fiscal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Col} xs={12} xl={12} controlId="chave">
          <Form.Label htmlFor="chave">Chave NFe</Form.Label>
          <Form.Control
            type="text"
            value={chave}
            onChange={(e) => setChave(e.target.value)}
          />
        </Form.Group>
        <hr />

        <Dropzone
          onDrop={async (acceptedFiles) => {
            setFiles(acceptedFiles);
          }}
          reactDropZoneProps={{
            multiple: true,
          }}
          accept={{
            "text/xml": [".xml"],
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="phoenix-secondary" onClick={handleExit}>
          Fechar
        </Button>
        <Button
          variant="primary"
          onClick={() => mutation.mutate({ chave, files })}
          loading={mutation.isPending}
          disabled={mutation.isPending}
          loadingPosition="start"
        >
          Importar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportModal;
