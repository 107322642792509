import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import classNames from "classnames";
import Button from "components/base/Button";
import React, { ChangeEvent, useRef, useState } from "react";
import { Alert, Form, InputGroup } from "react-bootstrap";
import { Navigate, useLocation } from "react-router-dom";

const totalInputLength = 6;

const postForgotPassword = async (data: any) => {
  return await axios.post("/api/user/forgot-password", data);
};

const postConfirmCode = async (data: any) => {
  return await axios.post("/api/user/confirm-forgot-password", data);
};

const TwoFAForm = ({ layout }: { layout?: "simple" | "card" | "split" }) => {
  const [otp, setOtp] = useState("");
  const [validationError, setValidationError] = useState<string | null>(null);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const resend = useMutation<
    any,
    any,
    {
      email: string;
    }
  >({
    mutationFn: postForgotPassword,
  });
  const confirm = useMutation<
    any,
    any,
    { email: string; code: string; newPassword: string }
  >({
    mutationFn: postConfirmCode,
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ): void => {
    const { value } = e.target;

    if (value) {
      [...value].slice(0, totalInputLength).forEach((char, charIndex) => {
        if (inputRefs.current && inputRefs.current[index + charIndex]) {
          inputRefs.current[index + charIndex]!.value = char;
          inputRefs.current[index + charIndex + 1]?.focus();
        }
      });
    } else {
      inputRefs.current[index]!.value = "";
      inputRefs.current[index - 1]?.focus();
    }

    const updatedOtp = inputRefs.current.reduce(
      (acc, input) => acc + (input?.value || ""),
      ""
    );
    setOtp(updatedOtp);
  };

  const validatePassword = (e: React.FormEvent<HTMLFormElement>) => {
    const password = e.currentTarget.password.value;
    const confirmPassword = e.currentTarget.confirmPassword.value;

    if (password !== confirmPassword) {
      setValidationError("As senhas não coincidem");
      return false;
    } else {
      setValidationError(null);
      return true;
    }
  };

  const location = useLocation();
  const { email, recovery } = location.state;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validatePassword(e)) {
      await confirm.mutate({
        email,
        code: otp,
        newPassword: e.currentTarget.password.value,
      });
    }
  };

  if (confirm.data && !confirm.error) {
    return <Navigate to="/pages/authentication/simple/sign-in" replace />;
  }

  return (
    <div>
      <div className={classNames({ "px-xxl-5": !(layout === "split") })}>
        <div
          className={classNames("text-center", {
            "mb-6": !(layout === "split"),
          })}
        >
          <h4 className="text-body-highlight">
            Digite o código de verificação
          </h4>
          <p className="text-body-tertiary mb-0">
            Enviaremos um email para {email} com um código de verificação se ele
            corresponder ao email cadastrado.
          </p>
          <div className="verification-form">
            <Form onSubmit={handleSubmit}>
              <div className="d-flex align-items-center gap-2 mb-3">
                {Array(totalInputLength)
                  .fill("")
                  .map((_, index) => (
                    <React.Fragment key={index}>
                      <Form.Control
                        ref={(el: HTMLInputElement) => {
                          inputRefs.current[index] = el;
                        }}
                        className="px-2 text-center"
                        type="number"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleChange(e, index)
                        }
                      />
                      {index === 2 && <span>-</span>}
                    </React.Fragment>
                  ))}
              </div>
              <Form.Control
                className="mb-2"
                id="password"
                type="password"
                placeholder="Digite uma nova senha"
                required
                isInvalid={!!validationError}
              />
              <InputGroup hasValidation>
                <Form.Control
                  id="confirmPassword"
                  type="password"
                  placeholder="Confirme a nova senha"
                  required
                  isInvalid={!!validationError}
                />
                <Form.Control.Feedback type="invalid">
                  {validationError}
                </Form.Control.Feedback>
              </InputGroup>
              <Button
                variant="primary"
                className="w-100 mb-5 mt-5"
                disabled={otp.length < totalInputLength || confirm.isPending}
                loading={confirm.isPending}
                loadingPosition="end"
                type="submit"
              >
                Verificar
              </Button>
              <Button
                className="w-100"
                variant="link"
                onClick={() => resend.mutate({ email })}
              >
                Não recebeu o código?
              </Button>
            </Form>
            {(confirm.error || resend.error) && (
              <Alert variant={"phoenix-danger"} className="mt-4">
                {confirm.error?.response?.data?.message ||
                  resend.error?.response?.data?.message}
              </Alert>
            )}
            {(confirm.data || resend.data) && (
              <Alert variant={"phoenix-success"} className="mt-4">
                {confirm.data?.message || resend?.data?.data?.message}
              </Alert>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFAForm;
