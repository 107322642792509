import { useMutation, useQuery } from "@tanstack/react-query";
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  RowSelectionState,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Button from "components/base/Button";
import Dropzone from "components/base/Dropzone";
import IndeterminateCheckbox from "components/base/IndeterminateCheckbox";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { ChangeEvent, useRef, useState } from "react";
import { Modal } from "react-bootstrap";

const fetchNcms = async () => {
  const { data } = await axios.get("/api/regraFiscal/ncm");
  return data;
};

type Data = {
  key: string;
  value: string;
};

const columns: ColumnDef<Data>[] = [
  {
    accessorKey: "key",
    header: "NCM",
  },
  {
    accessorKey: "value",
    header: "Descrição",
  },
];

const selectionColumn = {
  id: "select",
  accessorKey: "",
  cell: ({ row }: any) => (
    <IndeterminateCheckbox
      className="form-check fs-8 mb-0"
      type="radio"
      {...{
        checked: row.getIsSelected(),
        disabled: !row.getCanSelect(),
        indeterminate: row.getIsSomeSelected(),
        onChange: row.getToggleSelectedHandler(),
      }}
    />
  ),
  meta: {
    headerProps: { style: { width: "30px" } },
  },
};

const NcmModal = ({
  onConfirm,
  onCancel,
  isShow,
}: {
  onConfirm: (d: any) => void;
  onCancel: () => void;
  isShow: boolean;
}) => {
  const ncmsQuery = useQuery<Data[]>({
    queryKey: ["ncms"],
    queryFn: fetchNcms,
  });

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const table = useReactTable({
    data: ncmsQuery.data ?? [],
    columns: [selectionColumn, ...columns],
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableRowSelection: true,
    enableMultiRowSelection: false,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const handleConfirm = () => {
    const selectedRow = table
      .getRowModel()
      .rows.find((row) => row.getIsSelected());
    if (selectedRow) {
      onConfirm(selectedRow.original);
    }
  };

  return (
    <Modal
      show={isShow}
      onHide={onCancel}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Buscar NCM</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AdvanceTableProvider {...table}>
          <SearchBox
            placeholder="Buscar NCM"
            onChange={handleSearchInputChange}
            className="mx-auto mb-4"
          />
          <AdvanceTable
            tableProps={{
              size: "sm",
              className:
                "phoenix-table fs-9 mb-0 border-top border-translucent",
            }}
            rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
          />
          <AdvanceTableFooter navBtn />
        </AdvanceTableProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="phoenix-secondary" onClick={onCancel}>
          Fechar
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          Selecionar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NcmModal;
