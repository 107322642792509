// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import ComputerIcon from "@mui/icons-material/Computer";
// import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
// import LoopIcon from "@mui/icons-material/Loop";
// import MonitorIcon from "@mui/icons-material/Monitor";
// import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
// import StorageIcon from "@mui/icons-material/Storage";
// import StoreIcon from "@mui/icons-material/Store";
// import VpnKeyIcon from "@mui/icons-material/VpnKey";
import {
  faBusinessTime,
  faCalendarTimes,
  faCircleUser,
  faCode,
  faComputer,
  faFilePdf,
  faKey,
  faRedoAlt,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HttpTransportType, HubConnectionBuilder } from "@microsoft/signalr";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FitAddon } from "@xterm/addon-fit";
import axios from "axios";
import Button from "components/base/Button";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import SearchBox from "components/common/SearchBox";
import { servidoresBreadcrumbItems } from "data/servidores";
import FeatherIcon from "feather-icons-react";
import { useEffect, useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Terminal } from "xterm";
import "xterm/css/xterm.css";

interface BackendType {
  cnpj: string;
  razaoSocial: string;
  versao: string;
  standalone: boolean;
  configuracao: {
    producao: boolean;
    vendaNaoFiscal: boolean;
  };
  licenca: {
    id: string;
    hw: string;
    thumbprint: string;
    serial: string;
    issued: string;
    expiration: string;
    customer: string;
    roles: string[];
    pub: string;
  };
  registradoEm: string;
}

interface FrontendType {
  cnpj: string;
  razaoSocial: string;
  versao: string;
  maquinaId: string;
  usuario: string;
  registradoEm: string;
}

export interface ClienteType {
  connectionId: string;
  backend: BackendType;
  frontends: FrontendType[];
  cnpj: string;
  razaoSocial: string;
  versaoBackend: string;
  versaoGofaz: string;
  versaoAgente: string;
  hardwareId: string;
  standalone: boolean;
  homologacao: boolean;
  registradoEm: string;
  quantidadeTerminais: number;
  usuario: string;
  maquinaId: string;
}

const fetchVersoes = async ({ queryKey }: any) => {
  const [, _] = queryKey;
  const { data } = await axios.get(`/api/hubs/cliente/atualizacoes`);
  return data;
};

const fetchClientes = async ({ queryKey }: any) => {
  const [, _] = queryKey;
  const { data } = await axios.get(`/api/hubs/cliente`);
  return data;
};

const fetchHealthcheck = async ({ queryKey }: any) => {
  const [, chave] = queryKey;
  const { data } = await axios.post(`/api/hubs/cliente/healthcheck/${chave}`);
  return data;
};

const HealthCheck = ({ connectionId }: { connectionId: string }) => {
  const {
    data: healthCheck,
    error,
    isLoading,
  } = useQuery({
    queryFn: fetchHealthcheck,
    queryKey: ["healthCheck", connectionId],
  });

  if (isLoading) {
    return <p>Carregando...</p>;
  }

  if (error) {
    console.log("Erro ao carregar healthcheck: ", error);
    return <p>Erro ao carregar healthcheck.</p>;
  }

  return <pre>{JSON.stringify(healthCheck, null, 2)}</pre>;
};

const Term = ({ connectionId }: { connectionId: string }) => {
  const terminalRef = useRef<HTMLDivElement>(null);
  const terminal = useRef<Terminal | null>(null);
  const connection = useRef<any>(null);

  useEffect(() => {
    terminal.current = new Terminal();
    const fitAddon = new FitAddon();
    terminal.current.loadAddon(fitAddon);
    terminal.current.open(terminalRef.current!);

    terminal.current.focus();
    fitAddon.fit();

    // Create and start the SignalR connection
    connection.current = new HubConnectionBuilder()
      .withUrl("/ws/BackendHub", {
        transport: HttpTransportType.WebSockets,
      })
      .build();

    connection.current.on("RetornoDeComandoRecebido", (output: string) => {
      terminal.current?.write(output + "\r\n");
    });

    connection.current
      .start()
      .then(() => {
        let buff = "";
        terminal.current?.onData((data: string) => {
          if (data.charCodeAt(0) === 13) {
            // Enter key
            connection.current
              .invoke("ExecutarComando", connectionId, buff)
              .catch((err: Error) => console.error(err.toString()));
            buff = "";
            terminal.current?.write("\r\n");
            return;
          }

          if (data.charCodeAt(0) === 127) {
            // Backspace key
            terminal.current?.write("\b \b");
            buff = buff.slice(0, -1);
            return;
          }

          //control + c
          if (data.charCodeAt(0) === 3) {
            connection.current
              .invoke("ExecutarComando", connectionId, "^C")
              .catch((err: Error) => console.error(err.toString()));
            buff = "";
            terminal.current?.write("^C");
            return;
          }

          terminal.current?.write(data);
          buff += data;
        });
      })
      .catch((err: Error) => console.error(err.toString()));

    return () => {
      connection.current?.stop();
    };
  }, [connectionId]);

  return <div ref={terminalRef} style={{ height: "100%", width: "100%" }} />;
};

const Atualizacao = ({ connectionId }: { connectionId: string }) => {
  const [aviso, setAviso] = useState("");
  const { data, error, isLoading } = useQuery({
    queryFn: fetchVersoes,
    queryKey: ["atualizacoes"],
  });

  const mutation = useMutation({
    mutationFn: (data) => {
      return axios.post(`/api/hubs/Cliente/atualizar/${connectionId}`, data);
    },
    onError: (error: any) => {
      console.log("Erro ao carregar versões: ", error);
      setAviso("Erro ao carregar versões.");
    },
    onSuccess: () => {
      console.log("Versões carregadas com sucesso.");
      setAviso("Versões carregadas com sucesso.");
    },
  });

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const backend = formData.get("backend");
    const frontend = formData.get("frontend");

    console.log("Form data", backend, frontend);

    mutation.mutate({ backend, frontend } as any);
  };

  if (isLoading) {
    return <p>Carregando...</p>;
  }

  const versoesFrontend = data?.filter(
    //regex para pegar somente as versões do frontend que termine com .msi
    (versao: any) => versao.link.match(/.*\.msi.zip$/)
  );

  const versoesBackend = data?.filter(
    //regex para pegar somente as versões do backend-*.zip
    (versao: any) => versao.link.match(/backend-.*\.zip$/)
  );

  //a primeira da lista que nao tenha latest no nome
  const latestFrontend = versoesFrontend?.find(
    (versao: any) => !versao.nome.includes("latest")
  );

  const latestBackend = versoesBackend?.find(
    (versao: any) => !versao.nome.includes("latest")
  );

  return (
    <form className="w-100 d-flex gap-2 flex-column" onSubmit={handleSubmit}>
      {aviso && (
        <Alert variant={"phoenix-success"} className="mt-4">
          {aviso}
        </Alert>
      )}
      <div className="">
        <label htmlFor="backend" className="form-label">
          Versão backend
        </label>
        <input
          className="form-control"
          list="versoesBackend"
          id="backend"
          name="backend"
          placeholder="Digite para buscar..."
          defaultValue={latestBackend?.link}
        />
      </div>
      <div>
        <label htmlFor="frontend" className="form-label">
          Versão frontend
        </label>
        <input
          className="form-control"
          list="versoesFrontend"
          id="frontend"
          name="frontend"
          placeholder="Digite para buscar..."
          defaultValue={latestFrontend?.link}
        />
      </div>

      <datalist id="versoesBackend">
        {versoesBackend?.map((versao: any, index: number) => (
          <option key={index} value={versao.link} title={versao.nome}>
            {versao.nome}
          </option>
        ))}
      </datalist>
      <datalist id="versoesFrontend">
        {versoesFrontend?.map((versao: any, index: number) => (
          <option key={index} value={versao.link} title={versao.nome}>
            {versao.nome}
          </option>
        ))}
      </datalist>

      <Button
        variant="primary"
        type="submit"
        className="ms-auto mt-2 w-25"
        disabled={mutation.isPending}
      >
        Atualizar
        {mutation.isPending && (
          <span
            className="spinner-border spinner-border-sm"
            aria-hidden="true"
          ></span>
        )}
      </Button>
    </form>
  );
};

function Servidores() {
  const { data, error, isLoading, refetch } = useQuery<ClienteType[]>({
    queryFn: fetchClientes,
    queryKey: ["clientes"],
    refetchInterval: 1000,
  });
  const [searchTerm, setSearchTerm] = useState("");

  const backup = useMutation<string, string, string>({
    mutationFn: (data) => {
      return axios.post(`/api/hubs/Cliente/backup/${data}`);
    },
  });

  console.log(data);

  const handleRecarregar = async (connectionId: any) => {
    try {
      const response = await fetch(`/api/hubs/cliente/${connectionId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            axios.defaults?.headers?.common?.["Authorization"]?.toString() ||
            "",
        },
      });

      if (!response.ok) {
        throw new Error(`Erro HTTP: ${response.status}`);
      }

      await refetch();

      console.log("Dados recarregados com sucesso.");
    } catch (err) {
      console.error("Erro ao recarregar dados do backend: ", err);
    }
  };

  const handleRenewToken = async (connectionId: any) => {
    try {
      const response = await fetch(`/api/hubs/backend/${connectionId}/renew`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            axios.defaults?.headers?.common?.["Authorization"]?.toString() ||
            "",
        },
      });

      if (!response.ok) {
        throw new Error(`Erro HTTP: ${response.status}`);
      }

      console.log("Licença renovada com sucesso.");
    } catch (err) {
      console.error("Erro ao renovar licença do cliente: ", err);
    }
  };

  const handleAmbiente = async (connectionId: any) => {
    const cliente = data?.find(
      (cliente: any) => cliente.connectionId === connectionId
    );
    console.log("Configuracao", cliente?.backend.configuracao);
    try {
      const response = await fetch(
        `/api/hubs/backend/${connectionId}/configurar`,
        {
          method: "POST",
          body: JSON.stringify({
            producao: !cliente?.backend.configuracao.producao,
            vendaNaoFiscal: cliente?.backend.configuracao.vendaNaoFiscal,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization:
              axios.defaults?.headers?.common?.["Authorization"]?.toString() ||
              "",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Erro HTTP: ${response.status}`);
      }

      console.log("Ambiente alternado com sucesso.");
    } catch (err) {
      console.error("Erro ao alternar ambeinte do cliente: ", err);
    }
  };

  const handleVendaNaoFiscal = async (connectionId: any) => {
    const cliente = data?.find(
      (cliente: any) => cliente.connectionId === connectionId
    );
    console.log("Configuracao", cliente?.backend.configuracao);
    try {
      const response = await fetch(
        `/api/hubs/backend/${connectionId}/configurar`,
        {
          method: "POST",
          body: JSON.stringify({
            producao: cliente?.backend.configuracao.producao,
            vendaNaoFiscal: !cliente?.backend.configuracao.vendaNaoFiscal,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization:
              axios.defaults?.headers?.common?.["Authorization"]?.toString() ||
              "",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Erro HTTP: ${response.status}`);
      }

      console.log("Ambiente alternado com sucesso.");
    } catch (err) {
      console.error("Erro ao alternar ambeinte do cliente: ", err);
    }
  };

  const getCardBorderColor = (cliente: ClienteType) => {
    console.log(cliente);
    if (
      cliente.versaoBackend === "" ||
      (cliente.versaoBackend === "" && cliente.versaoAgente === "") ||
      (cliente.versaoBackend === "" &&
        cliente.versaoAgente === "" &&
        cliente.versaoGofaz === "")
    ) {
      return "rgba(255, 0, 0, 0.8)";
    } else if (
      cliente.versaoAgente === "" ||
      cliente.versaoGofaz === "" ||
      (cliente.versaoAgente === "" && cliente.versaoGofaz === "")
    ) {
      return "rgba(255, 255, 0, 0.8)";
    } else {
      return "rgba(0, 255, 0, 0.8)";
    }
  };

  return (
    <>
      <div>
        <PageBreadcrumb items={servidoresBreadcrumbItems} />
        <div className="mb-9">
          <h2 className="mb-5">Servidores</h2>
          <div className="mt-3 d-flex w-100 justify-content-center">
            <SearchBox
              className="w-100"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Buscar"
            />
          </div>

          {data &&
            data
              .filter(
                (cliente) =>
                  cliente.razaoSocial
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                  cliente.cnpj
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                  cliente.hardwareId
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase())
              )
              .map((item, index) => (
                <Accordion
                  key={item.connectionId}
                  className="mt-2"
                  style={{
                    border: "1px solid #000000",
                    borderColor: getCardBorderColor(item),
                    borderRadius: "5px",
                    zIndex: 9999,
                  }}
                >
                  <div className="w-100">
                    <Accordion.Header>
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <div className="d-flex">
                          <FeatherIcon
                            icon="home"
                            style={{ marginRight: "5px" }}
                          />
                          <h5 className="m-0">
                            {item.cnpj} - {item.razaoSocial?.toUpperCase()}
                          </h5>
                        </div>
                        <div className="d-flex">
                          <div className="me-2">
                            <Badge
                              pill
                              bg="secondary"
                              title="Quantidade terminais"
                            >
                              <FeatherIcon
                                icon="monitor"
                                style={{ marginRight: "5px" }}
                              />
                              {item.quantidadeTerminais}
                            </Badge>
                          </div>
                          <div className="me-2">
                            {item.versaoBackend ? (
                              <Badge
                                pill
                                bg="secondary"
                                title="Versão do backend"
                              >
                                <FeatherIcon
                                  icon="server"
                                  style={{ marginRight: "5px" }}
                                />
                                {item.versaoBackend}
                              </Badge>
                            ) : (
                              <Badge pill bg="danger" title="Versão do backend">
                                <FeatherIcon
                                  icon="server"
                                  style={{
                                    marginRight: "5px",
                                  }}
                                />
                                {"Indisponível"}
                              </Badge>
                            )}
                          </div>
                          <div className="me-2">
                            {item.versaoGofaz ? (
                              <Badge
                                pill
                                bg="secondary"
                                title="Versão do gofaz"
                              >
                                <FontAwesomeIcon
                                  icon={faFilePdf}
                                  style={{ marginRight: "5px" }}
                                />
                                {item.versaoGofaz}
                              </Badge>
                            ) : (
                              <Badge pill bg="danger" title="Versão do gofaz">
                                <FontAwesomeIcon
                                  icon={faFilePdf}
                                  size="xl"
                                  style={{ marginRight: "5px" }}
                                />
                                {"Indisponível"}
                              </Badge>
                            )}
                          </div>

                          <div className="me-2">
                            {item.homologacao === false ? (
                              <Badge
                                pill
                                bg="success"
                                title="Ambiente de produção"
                              >
                                <FeatherIcon
                                  icon="settings"
                                  style={{ marginRight: "5px" }}
                                />
                                Produção
                              </Badge>
                            ) : (
                              <Badge
                                pill
                                bg="danger"
                                title="Ambiente de homologação"
                              >
                                <FeatherIcon
                                  icon="settings"
                                  style={{ marginRight: "5px" }}
                                />
                                Homologação
                              </Badge>
                            )}
                          </div>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Tabs
                        defaultActiveKey="VisaoGeral"
                        id="justify-tab-example"
                        className="mb-3 w-100"
                        justify
                      >
                        <Tab
                          eventKey="VisaoGeral"
                          title="Visão Geral"
                          className="w-100"
                        >
                          <div className="d-flex w-100 justify-content-end">
                            <FontAwesomeIcon
                              onClick={() =>
                                handleRecarregar(item.connectionId)
                              }
                              icon={faRedoAlt}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                          <div className="w-100 d-flex justify-content-between">
                            <div className="d-flex flex-column">
                              <Card className="mt-2" title="Hardware ID">
                                <Card.Body>
                                  <FontAwesomeIcon
                                    icon={faComputer}
                                    style={{ marginRight: "5px" }}
                                  />
                                  {item.hardwareId}
                                </Card.Body>
                              </Card>
                              <Card className="mt-2" title="Data Criação">
                                <Card.Body>
                                  <FontAwesomeIcon
                                    icon={faBusinessTime}
                                    style={{ marginRight: "5px" }}
                                  />
                                  {item.backend?.licenca?.issued}
                                </Card.Body>
                              </Card>
                              <Card className="mt-2" title="Data Expiração">
                                <Card.Body>
                                  <FontAwesomeIcon
                                    icon={faCalendarTimes}
                                    style={{ marginRight: "5px" }}
                                  />
                                  {item.backend?.licenca?.expiration}
                                </Card.Body>
                              </Card>
                              <Card className="mt-2" title="Roles">
                                <Card.Body>
                                  {item.backend?.licenca?.roles &&
                                    item.backend?.licenca?.roles.map(
                                      (role, index) => (
                                        <div key={index}>
                                          <FontAwesomeIcon
                                            icon={faKey}
                                            style={{ marginRight: "5px" }}
                                          />
                                          {role}
                                        </div>
                                      )
                                    )}
                                </Card.Body>
                              </Card>
                            </div>
                            <Card
                              className="mt-2"
                              title="Chave pública"
                              style={{ maxWidth: "600px", maxHeight: "250px" }}
                            >
                              <Card.Body style={{ wordWrap: "break-word" }}>
                                <FontAwesomeIcon
                                  icon={faCode}
                                  style={{ marginRight: "5px" }}
                                />
                                {item.backend?.licenca?.pub}
                              </Card.Body>
                            </Card>
                          </div>
                          <div className="d-flex mt-4 align-items-end justify-content-end">
                            <Button
                              className="me-2"
                              variant="outline-primary"
                              onClick={() =>
                                handleRenewToken(item.connectionId)
                              }
                            >
                              Renovar Licença
                            </Button>
                            <Button
                              className="me-2"
                              variant="outline-primary"
                              onClick={() => handleAmbiente(item.connectionId)}
                            >
                              {item.homologacao ? "Produção" : "Homologação"}
                            </Button>
                            <Button
                              className="me-2"
                              variant="outline-primary"
                              loading={backup.isPending}
                              loadingPosition="start"
                              onClick={() => backup.mutate(item.connectionId)}
                            >
                              Backup
                            </Button>
                            <Button
                              className="me-2"
                              variant="outline-primary"
                              onClick={() =>
                                handleVendaNaoFiscal(item.connectionId)
                              }
                            >
                              {item.backend?.configuracao?.vendaNaoFiscal
                                ? "Desativar Venda Não Fiscal"
                                : "Ativar Venda Não Fiscal"}
                            </Button>
                          </div>
                        </Tab>

                        <Tab eventKey="Frontend" title="Frontend">
                          <div className="d-flex w-100 justify-content-end">
                            <FontAwesomeIcon
                              style={{ cursor: "pointer" }}
                              icon={faRotateRight}
                              onClick={() => handleRecarregar(item.hardwareId)}
                            />
                          </div>
                          <div className="w-100 d-flex flex-wrap justify-content-between">
                            {item.frontends?.map((frontend, frontendIndex) => (
                              <div key={frontendIndex}>
                                <Badge
                                  pill
                                  bg="secondary"
                                  title="Quantidade terminais"
                                >
                                  <FeatherIcon
                                    icon="monitor"
                                    style={{ marginRight: "5px" }}
                                  />
                                  {frontend.versao}
                                </Badge>
                                <Card className="mt-2" title="Usuário">
                                  <Card.Body>
                                    <FontAwesomeIcon
                                      icon={faCircleUser}
                                      style={{ marginRight: "5px" }}
                                    />
                                    {frontend.usuario}
                                  </Card.Body>
                                </Card>
                                <Card className="mt-2" title="Máquina ID">
                                  <Card.Body>
                                    <FeatherIcon
                                      icon="monitor"
                                      style={{ marginRight: "5px" }}
                                    />
                                    {frontend.maquinaId}
                                  </Card.Body>
                                </Card>
                              </div>
                            ))}
                          </div>
                        </Tab>

                        <Tab
                          eventKey="HealthCheck"
                          title="HealthCheck"
                          unmountOnExit
                        >
                          <HealthCheck connectionId={item.connectionId} />
                        </Tab>
                        <Tab
                          eventKey="Atualização"
                          title="Atualização"
                          unmountOnExit
                        >
                          <Atualizacao connectionId={item.connectionId} />
                        </Tab>
                        <Tab eventKey="Terminal" title="Terminal" unmountOnExit>
                          <Term connectionId={item.connectionId} />
                        </Tab>
                      </Tabs>
                    </Accordion.Body>
                  </div>
                  <Card style={{ borderRadius: "5px" }}>
                    <Card.Footer style={{ borderTop: "0" }}>
                      <div className="d-flex justify-content-end">
                        <small className="text-muted">
                          Registrado em: {""}
                          {new Date(item.registradoEm).toLocaleDateString()}
                          {" - "}
                          {new Date(item.registradoEm).toLocaleTimeString()}
                        </small>
                      </div>
                    </Card.Footer>
                  </Card>
                </Accordion>
              ))}
        </div>
      </div>
    </>
  );
}

export default Servidores;
