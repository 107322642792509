import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import RevealDropdown, {
  RevealDropdownTrigger,
} from "components/base/RevealDropdown";
import ActionDropdownItems from "components/common/ActionDropdownItems";
import { ClienteTableType } from "data/cliente";
import { currencyFormat } from "helpers/utils";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export const clienteTableColumns: ColumnDef<ClienteTableType>[] = [
  {
    accessorKey: "id",
    header: "ID",
    meta: {
      headerProps: { style: { width: 100 }, className: "ps-4" },
      cellProps: {
        className: "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85",
      },
    },
  },
  {
    accessorKey: "idTenant",
    header: "Tenant",
    meta: {
      headerProps: { style: { width: 100 }, className: "ps-4" },
      cellProps: {
        className: "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85",
      },
    },
  },
  {
    accessorKey: "razaoSocial",
    header: "Razão Social",
    cell: ({ row: { original } }) => {
      const { razaoSocial, id } = original;
      return (
        <Link to={`/clientes/${id}`} className="fw-semibold line-clamp-3">
          {razaoSocial}
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: 350 }, className: "ps-4" },
      cellProps: { className: "ps-4" },
    },
  },
  {
    accessorKey: "nomeFantasia",
    header: "Nome Fantasia",
    meta: {
      headerProps: { style: { width: 350 }, className: "ps-4" },
      cellProps: {
        className: "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85",
      },
    },
  },
  {
    accessorKey: "cnpj",
    header: "CNPJ",
    meta: {
      headerProps: { style: { width: 150 }, className: "ps-4" },
      cellProps: {
        className: "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85",
      },
    },
  },
  {
    id: "action",
    cell: ({ row: { original } }) => {
      const { razaoSocial, id } = original;
      <RevealDropdownTrigger>
        <RevealDropdown>
          <Dropdown.Item eventKey="1" as={Link} to={`/clientes/${id}`}>
            Visualizar
          </Dropdown.Item>
          <Dropdown.Item eventKey="1" as={Link} to={`/clientes/${id}`}>
            Visualizar
          </Dropdown.Item>
        </RevealDropdown>
      </RevealDropdownTrigger>;
    },
    meta: {
      headerProps: { style: { width: "7%" } },
      cellProps: { className: "text-end" },
    },
  },
];

const ClienteTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination showViewAllBtn={false} />
    </div>
  );
};

export default ClienteTable;
