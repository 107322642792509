import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  Updater,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import SearchBox from "components/common/SearchBox";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { ChangeEvent, useState } from "react";
import { Spinner } from "react-bootstrap";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import useDebounce from "hooks/useDebounce";
import { clientesBreadcrumbItems } from "data/cliente";
import ClienteTable, {
  clienteTableColumns,
} from "components/tables/ClienteTable";
import Button from "components/base/Button";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const fetchClientes = async ({
  pageIndex,
  pageSize,
  filter,
  sort,
}: {
  pageIndex: number;
  pageSize: number;
  filter?: string;
  sort?: SortingState;
}) => {
  // Base URL
  let url = `/api/admin/empresa?pageSize=${pageSize}&pageIndex=${pageIndex}`;

  // Adiciona o filter apenas se ele estiver definido e não for uma string vazia
  if (filter) {
    url += `&filter=${encodeURIComponent(filter)}`;
  }

  if (sort && sort.length > 0) {
    url += `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
  }

  const { data } = await axios.get(url);
  return data;
};

const Clientes = () => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [sort, setSort] = useState<SortingState>(!keepPreviousData ? [] : []);
  const [filter, setFilter] = useState<string | undefined>();

  const debouncedFilter = useDebounce(filter, 300);

  const dataQuery = useQuery({
    queryKey: ["clientes", pagination, debouncedFilter, sort],
    queryFn: () =>
      fetchClientes({
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        filter,
        sort,
      }),
    placeholderData: keepPreviousData,
  });

  const table = useReactTable({
    data: dataQuery.data?.itens ?? [],
    columns: clienteTableColumns,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    onGlobalFilterChange: setFilter,
    manualPagination: true,
    manualFiltering: true,
    manualSorting: true,
    onSortingChange: (updateOrValue: Updater<SortingState>) => {
      if (typeof updateOrValue === "function") {
        //intercepta a função de atualização do estado
        setSort((prev) => {
          const updated = updateOrValue(prev);
          //se for o mesmo id do state, inverte o desc
          if (prev[0]?.id === updated[0]?.id) {
            updated[0].desc = !prev[0]?.desc;
          }
          return updated;
        });
      } else {
        console.log(updateOrValue);
        setSort(updateOrValue);
      }
    },
    pageCount: dataQuery.data?.totalPages ?? 0,
    state: {
      globalFilter: "",
      pagination: {
        pageIndex: dataQuery.data?.pageIndex ?? 0,
        pageSize: dataQuery.data?.pageSize ?? 10,
      },
    },
    initialState: {
      pagination: {
        pageIndex: dataQuery.data?.pageIndex ?? 0,
        pageSize: dataQuery.data?.pageSize ?? 10,
      },
    },
  });

  if (dataQuery.isLoading)
    return (
      <Spinner animation="border" role="status" className="d-block mx-auto">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <PageBreadcrumb items={clientesBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">Clientes</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox
                placeholder="Pesquisar cliente"
                onChange={handleSearchInputChange}
              />
              <div className="ms-xxl-auto">
                <Button variant="primary" as={Link} to="/clientes/novo">
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Cadastrar
                </Button>
              </div>
            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <ClienteTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Clientes;
