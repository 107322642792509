import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/base/Button";
import React from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { FixedSizeList } from "react-window";

export interface FilterMenu {
  label: string;
  items: {
    onClick?: () => void;
    label: string;
    active?: boolean;
  }[];
  moreFilters?: {
    onClick?: () => void;
    label: string;
  };
}

interface FilterButtonGroupProps {
  menus: FilterMenu[];
}

const FilterButtonGroup = ({ menus }: FilterButtonGroupProps) => {
  return (
    <ButtonGroup className="position-static">
      {menus.map((menu) => (
        <Dropdown key={menu.label} as={ButtonGroup} className="position-static">
          <Dropdown.Toggle
            variant="phoenix-secondary"
            className="px-7 flex-shrink-0 dropdown-caret-none"
          >
            {menu.label}
            <FontAwesomeIcon
              icon={faAngleDown}
              className="ms-2"
              transform="down-2"
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <FixedSizeList
              height={menu.items.length > 5 ? 175 : menu.items.length * 35}
              itemCount={menu.items.length}
              itemSize={35}
              width="100%"
              itemData={menu.items}
            >
              {({ index, style }) => (
                <Dropdown.Item
                  key={index}
                  onClick={menu.items[index].onClick}
                  active={menu.items[index].active}
                  style={style}
                >
                  {menu.items[index].label}
                </Dropdown.Item>
              )}
            </FixedSizeList>
          </Dropdown.Menu>
        </Dropdown>
      ))}
      {menus[0].moreFilters && (
        <Button variant="phoenix-secondary" className="px-7 flex-shrink-0">
          Outros filtros
        </Button>
      )}
    </ButtonGroup>
  );
};

export default FilterButtonGroup;
