import { PageBreadcrumbItem } from "components/common/PageBreadcrumb";

export type FabricanteType = {
  id: number;
  codigo: number;
  descricao: string;
};

export type CriarFabricanteDtoType = {
  descricao: string;
};

export type AlterarFabricanteDtoType = {
  codigo: number;
  descricao: string;
};

export type FabricanteTableType = {
  id: number;
  codigo: number;
  descricao: string;
};

export const fabricantesBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: "Retaguarda",
    url: "#!",
  },
  {
    label: "Cadastros",
    url: "#!",
  },
  {
    label: "Fabricantes",
    active: true,
  },
];

export const fabricanteBreadcrumbItems = (
  codigo?: number
): PageBreadcrumbItem[] => [
  {
    label: "Retaguarda",
    url: "#!",
  },
  {
    label: "Cadastros",
    url: "#!",
  },
  {
    label: "Fabricante",
    url: "/retaguarda/cadastros/fabricantes",
  },
  {
    label: codigo?.toString() ?? "Novo",
    active: true,
  },
];
