import { useEffect, useState } from "react";

export function useConfirmation<TReturn, TArg = any, TErr = any>({
  initialArgs,
  deps,
}: { initialArgs?: TArg; deps?: any[] } = {}) {
  const [isShow, setShow] = useState({
    show: false,
    resolve: (data: TReturn) => {},
    reject: (err?: TErr) => {},
    args: {} as TArg,
  });

  useEffect(() => {
    if (deps) {
      setShow({
        ...isShow,
        args: initialArgs || ({} as TArg),
      });
    }
  }, deps);

  const onCancel = (err?: TErr) => {
    isShow.reject(err);
    setShow({
      show: false,
      resolve: (data: TReturn) => {},
      reject: (err?: TErr) => {},
      args: {} as TArg,
    });
  };

  const requestConfirmation = (payload?: TArg): Promise<TReturn> => {
    return new Promise((resolve, reject) => {
      setShow({
        show: true,
        resolve: (data: TReturn) => {
          setShow({
            show: false,
            resolve: (data: TReturn) => {},
            reject: (err?: TErr) => {},
            args: {} as TArg,
          });
          resolve(data);
        },
        reject,
        args: payload || initialArgs || ({} as TArg),
      });
    });
  };

  return {
    isShow: isShow.show,
    onConfirm: isShow.resolve,
    requestConfirmation,
    onCancel,
    args: isShow.args,
  };
}
