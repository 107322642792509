import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/base/Button";
import { Card, Col, Dropdown, Form, Row, Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import { defaultBreadcrumbItems } from "data/commonData";
import {
  faChevronDown,
  faPrint,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import {
  ICMS,
  NotaDetails,
  OtherDetails,
} from "components/modules/entradaDeNotas/NotaDetails";
import OrderDetailsSummaryCard from "components/cards/OrderDetailsSummaryCard";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import EntradaDeNotaDetTable, {
  entradaDeNotaDetTableColumns,
} from "components/tables/EntradaDeNotaDetTable";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { entradaDeNotaBreadcrumbItems } from "data/entradaDeNotas";

const fetchEntradaDeNota = async ({ queryKey }: any) => {
  const [_, id] = queryKey;
  const { data } = await axios.get(`/api/entradaDeNota/${id}`);

  return data;
};

const EntradaDeNotaResumo = () => {
  const { id } = useParams();
  const query = useQuery({
    queryKey: ["entradaDeNota", id],
    queryFn: fetchEntradaDeNota,
  });

  const table = useReactTable({
    data: query.data?.dets ?? [],
    columns: entradaDeNotaDetTableColumns,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  if (query.isLoading)
    return (
      <Spinner animation="border" role="status" className="d-block mx-auto">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );

  const { chaveAcesso, nnf } = query.data;

  return (
    <div>
      <PageBreadcrumb items={entradaDeNotaBreadcrumbItems(nnf)} />
      <div className="mb-9">
        <h2 className="mb-1">
          Nota Fiscal <span>#{nnf}</span>
        </h2>
        <div className="d-flex flex-wrap flex-between-center mb-4 gap-2">
          <p className="text-body-secondary lh-sm mb-0">{chaveAcesso}</p>
          <div className="d-flex gap-5">
            <Button
              variant="link"
              className="p-0 text-body text-decoration-none"
              startIcon={<FontAwesomeIcon icon={faPrint} className="me-2" />}
            >
              Print
            </Button>
            <Button
              variant="link"
              className="p-0 text-body text-decoration-none"
              startIcon={<FontAwesomeIcon icon={faUndo} className="me-2" />}
            >
              Refund
            </Button>
            <Dropdown>
              <Dropdown.Toggle
                variant=""
                className="p-0 dropdown-caret-none text-decoration-none"
              >
                More action
                <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <Row className="g-5 gy-7">
          <Col xs={12} xl={8} xxl={9}>
            <div className="mb-6">
              <AdvanceTableProvider {...table}>
                <EntradaDeNotaDetTable />
              </AdvanceTableProvider>
            </div>
            <Row className="gx-4 gy-6 g-xl-7 justify-content-sm-center justify-content-xl-start">
              <Col xs={12} sm="auto">
                <NotaDetails />
              </Col>

              <Col xs={12} sm="auto">
                <ICMS />
              </Col>

              <Col xs={12} sm="auto">
                <OtherDetails />
              </Col>
            </Row>
          </Col>
          <Col xs={12} xl={4} xxl={3}>
            <OrderDetailsSummaryCard className="mb-4" />
            <Card>
              <Card.Body>
                <Card.Title as="h3" className="mb-4">
                  Order Status
                </Card.Title>
                <h6 className="mb-2">Payment status</h6>
                <Form.Select className="mb-4">
                  <option value="processing">Processing</option>
                  <option value="canceled">Canceled</option>
                  <option value="completed">Completed</option>
                </Form.Select>
                <h6 className="mb-2">Fulfillment status</h6>
                <Form.Select>
                  <option value="unfulfilled">Unfulfilled</option>
                  <option value="fulfilled">Fulfilled</option>
                  <option value="Pending">Pending</option>
                </Form.Select>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EntradaDeNotaResumo;
