import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import RevealDropdown, {
  RevealDropdownTrigger,
} from "components/base/RevealDropdown";
import ActionDropdownItems from "components/common/ActionDropdownItems";
import { EntradaDeNotaTableType } from "data/entradaDeNotas";
import { ProdutoTableType } from "data/produto";
import { currencyFormat } from "helpers/utils";
import moment from "moment";
import { Link } from "react-router-dom";

export const entradaDeNotaTableColumns: ColumnDef<EntradaDeNotaTableType>[] = [
  {
    accessorKey: "numero",
    header: "Numero",
    meta: {
      headerProps: { style: { width: 150 }, className: "ps-4" },
      cellProps: {
        className: "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85",
      },
    },
  },
  {
    accessorKey: "fornecedor",
    header: "Fornecedor",
    cell: ({ row: { original } }) => {
      const { fornecedor, id } = original;
      return (
        <Link
          to={`/retaguarda/operacional/entrada-de-notas/${id}`}
          className="fw-semibold line-clamp-3"
        >
          {fornecedor}
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: 350 }, className: "ps-4" },
      cellProps: { className: "ps-4" },
    },
  },
  {
    accessorKey: "valor",
    header: "Valor",
    cell: ({ row: { original } }) => currencyFormat(original.valor),
    meta: {
      headerProps: { style: { width: 150 }, className: "ps-4" },
      cellProps: {
        className: "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85",
      },
    },
  },
  {
    accessorKey: "emissao",
    header: "Emissão",
    cell: ({ row: { original } }) =>
      moment(original.emissao).format("DD/MM/YYYY"),
    meta: {
      headerProps: { style: { width: 150 }, className: "ps-4" },
      cellProps: {
        className: "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85",
      },
    },
  },
  {
    id: "action",
    cell: () => (
      <RevealDropdownTrigger>
        <RevealDropdown>
          <ActionDropdownItems />
        </RevealDropdown>
      </RevealDropdownTrigger>
    ),
    meta: {
      headerProps: { style: { width: "7%" } },
      cellProps: { className: "text-end" },
    },
  },
];

const EntradaDeNotaTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination showViewAllBtn={false} />
    </div>
  );
};

export default EntradaDeNotaTable;
