import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Money from "components/base/InputMoney";
import Scrollbar from "components/base/Scrollbar";
import {
  AlterarDepartamentoDtoType,
  CriarDepartamentoDtoType,
} from "data/departamentos";
import { EmpresaQueryType, EmpresaType } from "data/empresa";
import { CriarProdutoDtoType, EditarProdutoDtoType } from "data/produto";
import { floatFormat } from "helpers/utils";
import { ChangeEvent } from "react";
import { Form, Table } from "react-bootstrap";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";

interface DepartamentoEmpresaTableProps {}

const fetchEmpresas = async () => {
  const { data } = await axios.get("/api/empresa");
  return data;
};

const DepartamentoEmpresaTable = ({}: DepartamentoEmpresaTableProps) => {
  const empresasQuery = useQuery<EmpresaQueryType>({
    queryKey: ["empresas"],
    queryFn: fetchEmpresas,
  });

  const { control } = useFormContext<
    CriarDepartamentoDtoType | AlterarDepartamentoDtoType
  >();

  const { fields } = useFieldArray({
    control,
    name: "empresas",
    rules: { required: true },
  });

  return (
    <Scrollbar autoHeight autoHeightMax="100%" className="table-scrollbar">
      <Table className="phoenix-table fs-9 mb-0 border-top border-translucent">
        <thead>
          <tr>
            <th scope="col">Empresa</th>
            <th className="text-center" scope="col" style={{ width: "20%" }}>
              Desconto
            </th>
          </tr>
        </thead>
        {empresasQuery.isSuccess && empresasQuery.data.itens.length > 0 && (
          <tbody className="list" id="cart-table-body">
            {empresasQuery.data.itens.map(
              (empresa: EmpresaType, index: number) => (
                <DepartamentoEmpresaRow
                  key={fields[index]?.id}
                  index={index}
                  empresa={empresa}
                />
              )
            )}
          </tbody>
        )}
      </Table>
    </Scrollbar>
  );
};

const DepartamentoEmpresaRow = ({
  empresa: { nomeFantasia, id },
  index,
}: {
  empresa: EmpresaType;
  index: number;
}) => {
  const {
    register,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useFormContext<CriarDepartamentoDtoType | AlterarDepartamentoDtoType>();

  return (
    <tr className="cart-table-row">
      <td>
        <Link className="fw-semibold line-clamp-2" to="#!">
          {nomeFantasia}
        </Link>
      </td>
      <input
        type="hidden"
        {...register(`empresas.${index}.idEmpresa`, {
          required: "Campo obrigatório",
        })}
        value={id}
      />
      <Form.Group
        as="td"
        className="text-center"
        controlId={`empresas.${index}.desconto`}
      >
        <Controller
          name={`empresas.${index}.desconto`}
          control={control}
          render={({ field }) => (
            <Money
              {...field}
              isInvalid={!!errors?.empresas?.[index]?.desconto}
            />
          )}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.empresas?.[index]?.desconto?.message}
        </Form.Control.Feedback>
      </Form.Group>
    </tr>
  );
};

export default DepartamentoEmpresaTable;
