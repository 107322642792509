import { currencyFormat } from "helpers/utils";
import { useState } from "react";
import { Form } from "react-bootstrap";

const Money = ({ onChange, onBlur, ...rest }: any) => {
  const format = (valor: string) => {
    if (!valor) return "";
    const v = valor.toString().replace(",", ".");
    return currencyFormat(parseFloat(v), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: "decimal",
    });
  };

  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState(format(rest.value));

  if (format(rest.value) !== value && !focused) {
    setValue(format(rest.value));
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange?.(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.value = format(value); // Formata o valor ao perder o foco
    onBlur?.(e);
    setValue(e.target.value);
    setFocused(false);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    e.target.select();
  };

  return (
    <Form.Control
      {...rest}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      style={{ textAlign: "right" }}
      onFocus={handleFocus}
    />
  );
};

export default Money;
