import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getCoreRowModel,
  getPaginationRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import Button from "components/base/Button";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import SearchBox from "components/common/SearchBox";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { ChangeEvent, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";

import { regrasFiscaisBreadcrumbItems } from "data/regraFiscal";
import RegraFiscalTable, {
  regraFiscalTableColumns,
} from "components/tables/RegraFiscalTable";

const fetchRegrasFiscais = async ({
  pageIndex,
  pageSize,
  filter,
}: PaginationState & { filter?: string }) => {
  const { data } = await axios.get("/api/regraFiscal", {
    params: {
      pageIndex,
      pageSize,
      filter,
    },
  });
  return data;
};

const RegrasFiscais = () => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [filter, setFilter] = useState<string | undefined>();

  const dataQuery = useQuery({
    queryKey: ["regrasFiscais", { ...pagination, filter }],
    queryFn: () => fetchRegrasFiscais({ ...pagination, filter }),
    placeholderData: keepPreviousData,
  });

  const revisaoQuery = useQuery({
    queryKey: ["revisaoRegrasFiscais"],
    queryFn: () => axios.get("/api/regraFiscal/revisando"),
    refetchInterval: 10000,
  });

  const revisaoMutation = useMutation({
    mutationFn: () => axios.post("/api/regraFiscal/revisar"),
  });

  const handleRevisar = async () => {
    await revisaoMutation.mutateAsync();
    revisaoQuery.refetch();
  };

  const table = useReactTable({
    data: dataQuery.data?.itens ?? [],
    columns: regraFiscalTableColumns,
    enableSorting: false,
    manualPagination: true,
    manualFiltering: true,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setFilter,
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    pageCount: dataQuery.data?.totalPages ?? 0,
    state: {
      globalFilter: "",
      pagination: {
        pageIndex: dataQuery.data?.pageIndex ?? 0,
        pageSize: dataQuery.data?.pageSize ?? 10,
      },
    },
    initialState: {
      pagination: {
        pageIndex: dataQuery.data?.pageIndex ?? 0,
        pageSize: dataQuery.data?.pageSize ?? 10,
      },
    },
  });

  if (dataQuery.isLoading)
    return (
      <Spinner animation="border" role="status" className="d-block mx-auto">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <PageBreadcrumb items={regrasFiscaisBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">Regras Fiscais</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox
                placeholder="Pesquisar regra fiscal"
                onChange={handleSearchInputChange}
              />
              <div className="ms-xxl-auto d-flex gap-2">
                <Button
                  variant="phoenix-secondary"
                  loading={
                    revisaoQuery.data?.data ||
                    revisaoQuery.isLoading ||
                    revisaoMutation.isPending
                  }
                  loadingPosition="start"
                  onClick={handleRevisar}
                >
                  Revisar
                </Button>
                <Button variant="outline-primary">Atualizar</Button>
                <Button
                  variant="primary"
                  as={Link}
                  to="/retaguarda/cadastros/regras-fiscais/novo"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Cadastrar
                </Button>
              </div>
            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <RegraFiscalTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default RegrasFiscais;
