import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import classNames from "classnames";
import Button from "components/base/Button";
import React, { ChangeEvent, useRef, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const totalInputLength = 6;

const postSentNewCode = async (data: any) => {
  return await axios.post("/api/user/resend-confirmation-code", data);
};

const postConfirmCode = async (data: any) => {
  return await axios.post("/api/user/confirm-registration", data);
};

const TwoFAForm = ({ layout }: { layout?: "simple" | "card" | "split" }) => {
  const [otp, setOtp] = useState("");
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const resend = useMutation<
    any,
    any,
    {
      email: string;
    }
  >({
    mutationFn: postSentNewCode,
  });
  const confirm = useMutation<
    any,
    any,
    { email: string; confirmationCode: string }
  >({
    mutationFn: postConfirmCode,
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ): void => {
    const { value } = e.target;

    if (value) {
      [...value].slice(0, totalInputLength).forEach((char, charIndex) => {
        if (inputRefs.current && inputRefs.current[index + charIndex]) {
          inputRefs.current[index + charIndex]!.value = char;
          inputRefs.current[index + charIndex + 1]?.focus();
        }
      });
    } else {
      inputRefs.current[index]!.value = "";
      inputRefs.current[index - 1]?.focus();
    }

    const updatedOtp = inputRefs.current.reduce(
      (acc, input) => acc + (input?.value || ""),
      ""
    );
    setOtp(updatedOtp);
  };

  const location = useLocation();
  const { email, recovery } = location.state;

  const maskedEmail = email?.replace(
    /(.{2})(.*)(?=@)/,
    (_: string, first: string, rest: string) => {
      return first + "*".repeat(rest.length);
    }
  );

  return (
    <div>
      <div className={classNames({ "px-xxl-5": !(layout === "split") })}>
        <div
          className={classNames("text-center", {
            "mb-6": !(layout === "split"),
          })}
        >
          <h4 className="text-body-highlight">
            Digite o código de verificação
          </h4>
          <p className="text-body-tertiary mb-0">
            Um email contendo um código de verificação de 6 dígitos foi enviado
            para o endereço de email - {maskedEmail}
          </p>
          {/* <p className="fs-10 mb-5">
            Don’t have access?
            <Link to="#!"> Use another method</Link>
          </p> */}
          <div className="verification-form">
            <div className="d-flex align-items-center gap-2 mb-3">
              {Array(totalInputLength)
                .fill("")
                .map((_, index) => (
                  <React.Fragment key={index}>
                    <Form.Control
                      ref={(el: HTMLInputElement) => {
                        inputRefs.current[index] = el;
                      }}
                      className="px-2 text-center"
                      type="number"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleChange(e, index)
                      }
                    />
                    {index === 2 && <span>-</span>}
                  </React.Fragment>
                ))}
            </div>
            <Form.Check type="checkbox" className="text-start mb-4">
              <Form.Check.Input
                type="checkbox"
                name="2fa-checkbox"
                id="2fa-checkbox"
              />
              <Form.Check.Label
                className="fs-8 fw-medium"
                htmlFor="2fa-checkbox"
              >
                Não perguntar novamente
              </Form.Check.Label>
            </Form.Check>
            <Button
              variant="primary"
              className="w-100 mb-5"
              disabled={otp.length < totalInputLength || confirm.isPending}
              onClick={() => confirm.mutate({ email, confirmationCode: otp })}
              loading={confirm.isPending}
              loadingPosition="end"
            >
              Verificar
            </Button>
            <Button
              className="w-100"
              variant="link"
              onClick={() => resend.mutate({ email })}
            >
              Não recebeu o código?
            </Button>
            {(confirm.error || resend.error) && (
              <Alert variant={"phoenix-danger"} className="mt-4">
                {confirm.error.response?.data.message ||
                  resend.error.response?.data.message}
              </Alert>
            )}
            {(confirm.data || resend.data) && (
              <Alert variant={"phoenix-success"} className="mt-4">
                {confirm.data?.message || resend?.data?.data?.message}
              </Alert>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFAForm;
