import App from "App";
import MainLayout from "layouts/MainLayout";
import Error403 from "pages/error/Error403";
import Error404 from "pages/error/Error404";
import Error500 from "pages/error/Error500";
import Logs from "pages/overwatch/hub";
import Implantacao from "pages/overwatch/implantacao";
import Licenca from "pages/overwatch/licenca";
import Servidores from "pages/overwatch/servidores";
import ForgotPassword from "pages/pages/authentication/simple/ForgotPassword";
import ForgotPasswordConfirm from "pages/pages/authentication/simple/ForgotPasswordConfirm";
import ResetPassword from "pages/pages/authentication/simple/ResetPassword";
import SignIn from "pages/pages/authentication/simple/SignIn";
import TwoFA from "pages/pages/authentication/simple/TwoFA";
import Departamentos from "pages/retaguarda/departamentos";
import EditarDepartamento from "pages/retaguarda/editarDepartamento";
import EditarEmpresa from "pages/retaguarda/editarEmpresa";
import EditarFabricante from "pages/retaguarda/editarFabricante";
import EditarPrincipioAtivo from "pages/retaguarda/editarPrincipioAtivo";
import EditarProduto from "pages/retaguarda/editarProduto";
import EditarRegraFiscal from "pages/retaguarda/editarRegraFiscal";
import EditarUnidadeDeMedida from "pages/retaguarda/editarUnidadeDeMedida";
import Empresas from "pages/retaguarda/empresas";
import EntradaDeNotaResumo from "pages/retaguarda/entradaDeNotaResumo";
import EntradaDeNota from "pages/retaguarda/entradaDeNotaResumo";
import EntradaDeNotas from "pages/retaguarda/entradaDeNotas";
import Fabricantes from "pages/retaguarda/fabricantes";
import NovaRegraFiscal from "pages/retaguarda/novaRegraFiscal";
import NovaUnidadeDeMedida from "pages/retaguarda/novaUnidadeDeMedida";
import NovoDepartamento from "pages/retaguarda/novoDepartamento";
import NovoFabricante from "pages/retaguarda/novoFabricante";
import NovoPrincipioAtivo from "pages/retaguarda/novoPrincipioAtivo";
import NovoProduto from "pages/retaguarda/novoProduto";
import PrincipiosAtivos from "pages/retaguarda/principiosAtivos";
import Produtos from "pages/retaguarda/produtos";
import RegrasFiscais from "pages/retaguarda/regrasFiscais";
import UnidadesDeMedida from "pages/retaguarda/unidadesDeMedida";
import Assinatura from "pages/revenda/assinatura";
import Clientes from "pages/revenda/clientes";
import EditarCliente from "pages/revenda/editarCliente";
import NovoCliente from "pages/revenda/novoCliente";
import ProtectedRoute from "ProtectedRoute";
import AuthProvider from "providers/AuthProvider";
import MainLayoutProvider from "providers/MainLayoutProvider";

import { lazy, Suspense } from "react";
import { Spinner } from "react-bootstrap";
import { createBrowserRouter, RouteObject } from "react-router-dom";

const routes: RouteObject[] = [
  {
    element: (
      <AuthProvider>
        <App />
      </AuthProvider>
    ),
    errorElement: <Error500 />,
    children: [
      {
        path: "/",
        element: (
          <MainLayoutProvider>
            <ProtectedRoute element={<MainLayout />}></ProtectedRoute>
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: <Clientes />,
          },
          {
            path: "clientes",
            children: [
              {
                index: true,
                element: <Clientes />,
              },
              {
                path: ":id",
                element: <EditarCliente />,
              },
              {
                path: ":id/assinatura",
                element: <Assinatura />,
              },
              {
                path: "novo",
                element: <NovoCliente />,
              },
            ],
          },
          {
            path: "/logs",
            element: <Logs />,
          },
          {
            path: "/licenca",
            element: <Licenca />,
          },
          {
            path: "/i/:connId",
            element: <Implantacao />,
          },
          {
            path: "/servidores",
            element: <Servidores />,
          },
          {
            path: "/retaguarda",
            children: [
              {
                path: "cadastros",
                children: [
                  {
                    path: "empresas",
                    children: [
                      {
                        index: true,
                        element: <Empresas />,
                      },
                      {
                        path: ":id",
                        element: <EditarEmpresa />,
                      },
                    ],
                  },
                  {
                    path: "produtos",
                    children: [
                      {
                        index: true,
                        element: <Produtos />,
                      },
                      {
                        path: ":id",
                        element: <EditarProduto />,
                      },
                      {
                        path: "novo",
                        element: <NovoProduto />,
                      },
                    ],
                  },
                  {
                    path: "unidades-de-medida",
                    children: [
                      {
                        index: true,
                        element: <UnidadesDeMedida />,
                      },
                      {
                        path: ":id",
                        element: <EditarUnidadeDeMedida />,
                      },
                      {
                        path: "novo",
                        element: <NovaUnidadeDeMedida />,
                      },
                    ],
                  },
                  {
                    path: "departamentos",
                    children: [
                      {
                        index: true,
                        element: <Departamentos />,
                      },
                      {
                        path: ":id",
                        element: <EditarDepartamento />,
                      },
                      {
                        path: "novo",
                        element: <NovoDepartamento />,
                      },
                    ],
                  },
                  {
                    path: "fabricantes",
                    children: [
                      {
                        index: true,
                        element: <Fabricantes />,
                      },
                      {
                        path: ":id",
                        element: <EditarFabricante />,
                      },
                      {
                        path: "novo",
                        element: <NovoFabricante />,
                      },
                    ],
                  },
                  {
                    path: "principios-ativos",
                    children: [
                      {
                        index: true,
                        element: <PrincipiosAtivos />,
                      },
                      {
                        path: ":id",
                        element: <EditarPrincipioAtivo />,
                      },
                      {
                        path: "novo",
                        element: <NovoPrincipioAtivo />,
                      },
                    ],
                  },
                  {
                    path: "regras-fiscais",
                    children: [
                      {
                        index: true,
                        element: <RegrasFiscais />,
                      },
                      {
                        path: ":id",
                        element: <EditarRegraFiscal />,
                      },
                      {
                        path: "novo",
                        element: <NovaRegraFiscal />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "operacional",
                children: [
                  {
                    path: "entrada-de-notas",
                    children: [
                      {
                        index: true,
                        element: <EntradaDeNotas />,
                      },
                    ],
                  },
                  {
                    path: "entrada-de-notas/:id",
                    children: [
                      {
                        index: true,
                        element: <EntradaDeNotaResumo />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/pages/errors/",
        children: [
          {
            path: "404",
            element: <Error404 />,
          },
          {
            path: "403",
            element: <Error403 />,
          },
          {
            path: "500",
            element: <Error500 />,
          },
        ],
      },
      {
        path: "/pages/authentication/simple/",
        children: [
          {
            path: "sign-in",
            element: <SignIn />,
          },
          {
            path: "reset-password",
            element: <ResetPassword />,
          },
          {
            path: "2FA",
            element: <TwoFA />,
          },
          {
            path: "forgot-password",
            element: <ForgotPassword />,
          },
          {
            path: "forgot-confirm",
            element: <ForgotPasswordConfirm />,
          },
        ],
      },
      {
        path: "*",
        element: <Error404 />,
      },
    ],
  },
];

export const router = createBrowserRouter(routes);

export default routes;
