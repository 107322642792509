import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import Button from "components/base/Button";
import { EntradaDeNotaDetTableType } from "data/entradaDeNotas";
import { currencyFormat } from "helpers/utils";
import FeatherIcon from "feather-icons-react";
import RevealDropdown, {
  RevealDropdownTrigger,
} from "components/base/RevealDropdown";
import ActionDropdownItems from "components/common/ActionDropdownItems";
import { Dropdown } from "react-bootstrap";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const entradaDeNotaDetTableColumns: ColumnDef<EntradaDeNotaDetTableType>[] =
  [
    {
      accessorKey: "action",
      enableSorting: false,
      header: "",
      cell: () => {
        return (
          <div className="d-flex gap-2 flex-wrap">
            <RevealDropdownTrigger>
              <Dropdown align="end">
                <Dropdown.Toggle size="sm" className="w-100" as={Button}>
                  <FontAwesomeIcon icon={faEllipsis} className="fs-10" /> Ações
                </Dropdown.Toggle>
                <Dropdown.Menu align="end" className={"py-2"}>
                  <Dropdown.Item eventKey="1">Cadastrar</Dropdown.Item>
                  <Dropdown.Item eventKey="2">Vincular</Dropdown.Item>
                  <Dropdown.Item eventKey="3">Trocar vinculado</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </RevealDropdownTrigger>
            <Badge
              bg="warning"
              variant="phoenix"
              iconPosition="end"
              className="fs-10"
              icon={
                <FeatherIcon icon="alert-triangle" size={12} className="ms-1" />
              }
            >
              Não cadastrado
            </Badge>
          </div>
        );
      },
      meta: {
        cellProps: { className: "text-end" },
      },
    },
    {
      accessorKey: "xProd",
      header: "Descrição",
      meta: {
        headerProps: { style: { width: 350 }, className: "ps-4" },
        cellProps: { className: "ps-4" },
      },
    },
    {
      accessorKey: "cEAN",
      header: "Código de Barras",
      meta: {
        headerProps: { className: "ps-4" },
        cellProps: { className: "ps-4" },
      },
    },
    {
      accessorKey: "qCom",
      header: "Qtd",
      meta: {
        headerProps: { className: "ps-4" },
        cellProps: {
          className: "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85",
        },
      },
    },
    {
      accessorKey: "vUnCom",
      header: "Valor Un.",
      cell: ({ row: { original } }) => currencyFormat(original.vUnCom),
      meta: {
        headerProps: { className: "ps-4 text-end" },
        cellProps: {
          className:
            "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85 text-end",
        },
      },
    },
    {
      accessorKey: "vProd",
      header: "Valor Total",
      cell: ({ row: { original } }) => currencyFormat(original.vProd),
      meta: {
        headerProps: { className: "ps-4 text-end" },
        cellProps: {
          className:
            "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85 text-end",
        },
      },
    },
    {
      accessorKey: "vDesc",
      header: "Desc.",
      cell: ({ row: { original } }) => currencyFormat(original.vDesc),
      meta: {
        headerProps: { className: "ps-4 text-end" },
        cellProps: {
          className:
            "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85 text-end",
        },
      },
    },
    {
      accessorKey: "custo",
      header: "Custo Un.",
      cell: ({ row: { original } }) => currencyFormat(original.custo),
      meta: {
        headerProps: { className: "ps-4 text-end" },
        cellProps: {
          className:
            "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85 text-end",
        },
      },
    },
    {
      accessorKey: "cfop",
      header: "CFOP",
      meta: {
        headerProps: { className: "ps-4 text-end" },
        cellProps: {
          className:
            "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85 text-end",
        },
      },
    },
  ];

const EntradaDeNotaDetTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination showViewAllBtn={false} />
    </div>
  );
};

export default EntradaDeNotaDetTable;
