import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Button from "components/base/Button";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import { transformErrors } from "helpers/utils";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import { EmpresaBrasilApi } from "data/brasilApi";
import { clienteBreadcrumbItems } from "data/cliente";
import {
  CriarTenantDtoType,
  regimesTributarios,
  tenantBreadcrumbItems,
} from "data/tenant";

const fetchEmpresa = async ({ queryKey }: any) => {
  const [, cnpj] = queryKey;
  const cnpjLimpo = cnpj.replace(/\D/g, "");
  const { data } = await axios.get(
    `https://brasilapi.com.br/api/cnpj/v1/${cnpjLimpo}`,
    {
      withCredentials: false,
    }
  );
  return data;
};

const NovoCliente = () => {
  const form = useForm<CriarTenantDtoType>({
    defaultValues: {},
  });

  const mutation = useMutation<any, any, CriarTenantDtoType>({
    mutationFn: (p: CriarTenantDtoType) => {
      return axios.post("/api/admin/empresa", p);
    },
    onError: (error) => {
      const formattedErrors = transformErrors(error.response.data.errors);
      for (const [field, error] of Object.entries(formattedErrors)) {
        form.setError(field as any, error as any);
      }
    },
  });

  const dadosEmpresaQuery = useQuery<EmpresaBrasilApi>({
    queryKey: ["tenant", form.getValues().cnpj, form.watch("cnpj")],
    queryFn: fetchEmpresa,
    enabled: !!form.getValues().cnpj,
  });

  useEffect(() => {
    const { data } = dadosEmpresaQuery;
    if (!data || mutation.isError) return;
    form.reset({
      razaoSocial: data?.razao_social,
      nomeFantasia: data?.nome_fantasia,
      telefone: data?.ddd_telefone_1,
      email: data?.email,
      responsavel: data?.qsa?.[0]?.nome_socio,
      endereco: {
        bairro: data?.bairro,
        cep: data?.cep,
        complemento: data?.complemento,
        descricao: data?.logradouro,
        logradouro: data?.logradouro,
        municipio: data?.municipio,
        ibge: data?.codigo_municipio_ibge,
        uf: data?.uf,
        numero: data?.numero,
      },
    });
  }, [dadosEmpresaQuery.data]);

  const onSubmit: SubmitHandler<CriarTenantDtoType> = (data) => {
    mutation.mutate(data);
  };

  if (mutation.isSuccess) {
    const id = mutation.data.data.id;
    return <Navigate to={`/clientes/${id}`} />;
  }

  return (
    <div>
      <FormProvider {...form}>
        <PageBreadcrumb items={tenantBreadcrumbItems()} />
        <Form
          noValidate
          className="mb-9"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
            <div>
              <h2 className="mb-2">Cadastrar um cliente</h2>
              <h5 className="text-body-tertiary fw-semibold">
                Preencha as informações para cadastrar um novo cliente
              </h5>
            </div>
            <div className="d-flex flex-wrap gap-2">
              <Button variant="phoenix-secondary" type="button">
                Descartar
              </Button>
              <Button
                variant="primary"
                type="submit"
                loading={mutation.isPending}
                disabled={mutation.isPending}
                loadingPosition="start"
              >
                Salvar
              </Button>
            </div>
          </div>
          <Row className="g-5">
            <Col xs={12} xl={8}>
              <h4 className="mb-3">Identificação do cliente</h4>
              <Row className="g-2 mb-3">
                <Form.Group as={Col} xs={12} xl={2} controlId="cnpj">
                  <Form.Label htmlFor="cnpj">CNPJ</Form.Label>
                  <Form.Control
                    placeholder="CNPJ"
                    {...form.register("cnpj", {
                      required: "Campo obrigatório",
                    })}
                    onBlur={() => {
                      form.setValue(
                        "cnpj",
                        form.getValues().cnpj.replace(/\D/g, "")
                      );

                      dadosEmpresaQuery.refetch();
                    }}
                    required
                    isInvalid={!!form.formState.errors.cnpj}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.cnpj?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={12} xl={4} controlId="razaoSocial">
                  <Form.Label htmlFor="razaoSocial">Razão Social</Form.Label>
                  <Form.Control
                    placeholder="Razão social"
                    {...form.register("razaoSocial")}
                    required
                    isInvalid={!!form.formState.errors.razaoSocial}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.razaoSocial?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={12} xl={4} controlId="nomeFantasia">
                  <Form.Label htmlFor="nomeFantasia">Nome Fantasia</Form.Label>
                  <Form.Control
                    placeholder="Nome fantasia"
                    {...form.register("nomeFantasia")}
                    required
                    isInvalid={!!form.formState.errors.nomeFantasia}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.nomeFantasia?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={12}
                  xl={2}
                  controlId="inscricaoEstadual"
                >
                  <Form.Label htmlFor="inscricaoEstadual">
                    Inscrição Estadual
                  </Form.Label>
                  <Form.Control
                    placeholder="Inscrição estadual"
                    {...form.register("inscricaoEstadual")}
                    required
                    isInvalid={!!form.formState.errors.inscricaoEstadual}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.inscricaoEstadual?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={12}
                  xl={2}
                  controlId="regimeTributario"
                >
                  <Form.Label htmlFor="regimeTributario">
                    Regime tributário
                  </Form.Label>
                  <Form.Select {...form.register("regimeTributario")}>
                    {regimesTributarios.map((r) => (
                      <option key={r.value} value={r.value}>
                        {r.label}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.regimeTributario?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <h4 className="mb-3">Dados de contato</h4>
              <Row className="g-2 mb-3">
                <Form.Group as={Col} xs={12} xl={2} controlId="telefone">
                  <Form.Label htmlFor="telefone">Telefone</Form.Label>
                  <Form.Control
                    placeholder="Telefone"
                    {...form.register("telefone")}
                    required
                    isInvalid={!!form.formState.errors.telefone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.telefone?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={12} xl={4} controlId="email">
                  <Form.Label htmlFor="email">E-mail</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="E-mail"
                    {...form.register("email")}
                    required
                    isInvalid={!!form.formState.errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.email?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={12} xl={6} controlId="responsavel">
                  <Form.Label htmlFor="responsavel">Responsável</Form.Label>
                  <Form.Control
                    placeholder="Responsável"
                    {...form.register("responsavel")}
                    required
                    isInvalid={!!form.formState.errors.responsavel}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.responsavel?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <h4 className="mb-3">Endereço</h4>
              <Row className="g-2 mb-3">
                <Form.Group as={Col} xs={12} xl={2} controlId="endereco.cep">
                  <Form.Label htmlFor="endereco.cep">CEP</Form.Label>
                  <Form.Control
                    placeholder="CEP"
                    {...form.register("endereco.cep")}
                    required
                    isInvalid={!!form.formState.errors.endereco?.cep}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.endereco?.cep?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={8}
                  xl={8}
                  controlId="endereco.logradouro"
                >
                  <Form.Label htmlFor="endereco.logradouro">
                    Logradouro
                  </Form.Label>
                  <Form.Control
                    placeholder="Logradouro"
                    {...form.register("endereco.logradouro")}
                    required
                    isInvalid={!!form.formState.errors.endereco?.logradouro}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.endereco?.logradouro?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={4} xl={2} controlId="endereco.numero">
                  <Form.Label htmlFor="endereco.numero">Número</Form.Label>
                  <Form.Control
                    placeholder="Número"
                    {...form.register("endereco.numero")}
                    required
                    isInvalid={!!form.formState.errors.endereco?.numero}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.endereco?.numero?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  as={Col}
                  xs={12}
                  xl={4}
                  controlId="endereco.complemento"
                >
                  <Form.Label htmlFor="endereco.complemento">
                    Complemento
                  </Form.Label>
                  <Form.Control
                    placeholder="Complemento"
                    {...form.register("endereco.complemento")}
                    required
                    isInvalid={!!form.formState.errors.endereco?.complemento}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.endereco?.complemento?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={6} xl={4} controlId="endereco.bairro">
                  <Form.Label htmlFor="endereco.bairro">Bairro</Form.Label>
                  <Form.Control
                    placeholder="Bairro"
                    {...form.register("endereco.bairro")}
                    required
                    isInvalid={!!form.formState.errors.endereco?.bairro}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.endereco?.bairro?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={6}
                  xl={4}
                  controlId="endereco.municipio"
                >
                  <Form.Label htmlFor="endereco.municipio">
                    Município
                  </Form.Label>
                  <Form.Control
                    placeholder="Município"
                    {...form.register("endereco.municipio")}
                    required
                    isInvalid={!!form.formState.errors.endereco?.municipio}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.endereco?.municipio?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} xs={6} xl={2} controlId="endereco.uf">
                  <Form.Label htmlFor="endereco.uf">UF</Form.Label>
                  <Form.Control
                    placeholder="UF"
                    {...form.register("endereco.uf")}
                    required
                    isInvalid={!!form.formState.errors.endereco?.uf}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.endereco?.uf?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </div>
  );
};

export default NovoCliente;
