import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axios from "axios";
import FilterButtonGroup, {
  FilterMenu,
} from "components/common/FilterButtonGroup";
import { EmpresaQueryType } from "data/empresa";
import { FornecedorMenuType } from "data/entradaDeNotas";
import { Placeholder } from "react-bootstrap";

const fetchEmpresas = async () => {
  const { data } = await axios.get("/api/empresa");
  return data;
};

const fetchFornecedores = async () => {
  const { data } = await axios.get("/api/fornecedor");
  return data;
};

export interface Filter {
  empresa?: number;
  fornecedor?: number;
}

export default function Menus({
  filters,
  onFilter,
}: {
  filters: Filter;
  onFilter: (filter: Filter) => void;
}) {
  const empresasQuery = useQuery<EmpresaQueryType>({
    queryKey: ["empresas"],
    queryFn: fetchEmpresas,
    placeholderData: keepPreviousData,
  });

  const fornecedoresQuery = useQuery<FornecedorMenuType[]>({
    queryKey: ["fornecedores"],
    queryFn: fetchFornecedores,
    placeholderData: keepPreviousData,
  });

  const empresas =
    empresasQuery.data?.itens?.map((d) => {
      return {
        label: d.nomeFantasia,
        active: filters.empresa === d.id,
        onClick: () => onFilter({ ...filters, empresa: d.id }),
      };
    }) ?? [];

  const fornecedores =
    fornecedoresQuery.data?.map((d) => {
      return {
        label: d.razaoSocial,
        active: filters.fornecedor === d.id,
        onClick: () => onFilter({ ...filters, fornecedor: d.id }),
      };
    }) ?? [];

  const filterMenus: FilterMenu[] = [
    {
      label: "Empresa",
      items: [
        {
          label: "Todas",
          active: filters.empresa === undefined,
          onClick: () => onFilter({ empresa: undefined }),
        },
        ...empresas,
      ],
    },
    {
      label: "Fornecedor",
      items: [
        {
          label: "Todos",
          active: filters.fornecedor === undefined,
          onClick: () => onFilter({ fornecedor: undefined }),
        },
        ...fornecedores,
      ],
    },
  ];

  if (empresasQuery.isLoading || fornecedoresQuery.isLoading) {
    return (
      <Placeholder xs={4} animation="wave" size="lg" as="div" className="mt-1">
        <Placeholder xs={12} size="lg" />
      </Placeholder>
    );
  }

  return (
    <div className="scrollbar overflow-hidden-y">
      <FilterButtonGroup menus={filterMenus} />
    </div>
  );
}
