import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import RevealDropdown, {
  RevealDropdownTrigger,
} from "components/base/RevealDropdown";
import ActionDropdownItems from "components/common/ActionDropdownItems";
import { EmpresaTableType } from "data/empresa";
import { UnidadeMedidaTableType } from "data/unidadesDeMedida";
import { currencyFormat } from "helpers/utils";
import { Link } from "react-router-dom";

export const unidadesDeMedidaTableColumns: ColumnDef<UnidadeMedidaTableType>[] =
  [
    {
      accessorKey: "sigla",
      header: "Sigla",
      meta: {
        headerProps: { style: { width: 100 }, className: "ps-4" },
        cellProps: {
          className: "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85",
        },
      },
    },
    {
      accessorKey: "descricao",
      header: "Descrição",
      cell: ({ row: { original } }) => {
        const { descricao, id } = original;
        return (
          <Link
            to={`/retaguarda/cadastros/unidades-de-medida/${id}`}
            className="fw-semibold line-clamp-3"
          >
            {descricao}
          </Link>
        );
      },
      meta: {
        headerProps: { style: { width: 350 }, className: "ps-4" },
        cellProps: { className: "ps-4" },
      },
    },
    {
      id: "action",
      cell: () => (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <ActionDropdownItems />
          </RevealDropdown>
        </RevealDropdownTrigger>
      ),
      meta: {
        headerProps: { style: { width: "7%" } },
        cellProps: { className: "text-end" },
      },
    },
  ];

const UnidadesDeMedidaTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination showViewAllBtn={false} />
    </div>
  );
};

export default UnidadesDeMedidaTable;
