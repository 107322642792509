import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import RevealDropdown, {
  RevealDropdownTrigger,
} from "components/base/RevealDropdown";
import ActionDropdownItems from "components/common/ActionDropdownItems";
import { PrincipioAtivoTableType } from "data/principiosAtivos";
import { Link } from "react-router-dom";

export const principioAtivoTableColumns: ColumnDef<PrincipioAtivoTableType>[] =
  [
    {
      accessorKey: "codigo",
      header: "Código",
      meta: {
        headerProps: { style: { width: 100 }, className: "ps-4" },
        cellProps: {
          className: "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85",
        },
      },
    },
    {
      accessorKey: "descricao",
      header: "Descrição",
      cell: ({ row: { original } }) => {
        const { descricao, id } = original;
        return (
          <Link
            to={`/retaguarda/cadastros/principios-ativos/${id}`}
            className="fw-semibold line-clamp-3"
          >
            {descricao}
          </Link>
        );
      },
      meta: {
        headerProps: { style: { width: 350 }, className: "ps-4" },
        cellProps: { className: "ps-4" },
      },
    },
    {
      id: "action",
      cell: () => (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <ActionDropdownItems />
          </RevealDropdown>
        </RevealDropdownTrigger>
      ),
      meta: {
        headerProps: { style: { width: "7%" } },
        cellProps: { className: "text-end" },
      },
    },
  ];

const PrincipioAtivoTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination showViewAllBtn={false} />
    </div>
  );
};

export default PrincipioAtivoTable;
