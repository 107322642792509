import { CSSProperties } from "react";
import { Col, Form, Nav, Row, Tab } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import Button from "components/base/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactSelect from "components/base/ReactSelect";
import classNames from "classnames";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { Controller, useFormContext } from "react-hook-form";
import {
  CriarProdutoDtoType,
  lista,
  pbm,
  receituarios,
  tipoControle,
} from "data/produto";
import AsyncReactSelect from "components/base/AsyncReactSelect";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { UnidadeMedidaType } from "data/unidadesDeMedida";

type NavItemType = {
  label: string;
  icon: string;
  eventKey: string;
};

const options = [
  { value: "canada", label: "Canada" },
  { value: "mexico", label: "Mexico" },
  { value: "united-kingdom", label: "United Kingdom" },
  { value: "united-states-of-america", label: "United States of America" },
];

const NavItem = ({ item, isLast }: { item: NavItemType; isLast?: boolean }) => {
  return (
    <Nav.Link
      eventKey={item.eventKey}
      className={classNames(
        "text-center text-sm-start cursor-pointer outline-none d-sm-flex align-items-sm-center",
        {
          "border-bottom-sm border-end border-end-sm-0": !isLast,
        }
      )}
    >
      <FeatherIcon icon={item.icon} size={16} className="me-sm-2 nav-icons" />
      <span className="d-none d-sm-inline">{item.label}</span>
    </Nav.Link>
  );
};

const navItems: NavItemType[] = [
  {
    label: "Códigos de Barras",
    icon: "box",
    eventKey: "codigosDeBarras",
  },
  {
    label: "Estoque",
    icon: "package",
    eventKey: "estoque",
  },
  {
    label: "Controlado",
    icon: "file-text",
    eventKey: "controlado",
  },
  {
    label: "Atributos",
    icon: "sliders",
    eventKey: "atributos",
  },
];

const fetchUnidadeMedida = async () => {
  const { data } = await axios.get("/api/unidadeMedida");
  return data;
};

const InventoryTab = () => {
  const unidadeMedidaQuery = useQuery<UnidadeMedidaType[]>({
    queryKey: ["unidadeMedida"],
    queryFn: fetchUnidadeMedida,
  });

  const {
    register,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<CriarProdutoDtoType>();

  const unidadeMedidaSelecionada = unidadeMedidaQuery?.data
    ?.map((e) => ({ label: e.descricao, value: e.id }))
    .find((d) => d.value == watch("idUnidadeMedida"));

  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="codigosDeBarras">
      <Row
        className="g-0 border-top border-bottom"
        style={{
          minHeight: "260px",
        }}
      >
        <Col xs={12} sm={4}>
          <Nav className="flex-sm-column border-bottom border-bottom-sm-0 border-end-sm fs-9 vertical-tab h-100 justify-content-between">
            {navItems.map((item, index) => (
              <NavItem
                key={item.label}
                item={item}
                isLast={index === navItems.length - 1}
              />
            ))}
          </Nav>
        </Col>

        <Col xs={12} sm={8}>
          <Tab.Content className="py-3 ps-sm-4 h-100">
            <Tab.Pane eventKey="codigosDeBarras" className="h-100">
              <h5 className="mb-3 text-body-highlight">Códigos de Barras</h5>
              <div className="mb-3">
                <Controller
                  name="codigosDeBarras"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <AsyncReactSelect
                      {...field}
                      isMulti
                      placeholder=""
                      openMenuOnFocus={false}
                      openMenuOnClick={false}
                      value={watch("codigosDeBarras")?.map((v) => ({
                        value: v,
                        label: v,
                      }))}
                      onChange={(value: any) => {
                        setValue(
                          "codigosDeBarras",
                          value.map((v: any) => v.value)
                        );
                      }}
                      required
                      styles={{
                        control: (baseStyles) =>
                          ({
                            ...baseStyles,
                            height: "100px",
                            alignItems: "start",
                          }) as any,
                        dropdownIndicator: (baseStyles) =>
                          ({
                            ...baseStyles,
                            display: "none",
                          }) as any,
                      }}
                    />
                  )}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="estoque" className="h-100">
              <div className="d-flex flex-column h-100">
                <h5 className="mb-3 text-body-highlight">Estoque</h5>
                <div className="row g-3">
                  <Form.Group
                    as="div"
                    className="col-6 col-lg-6"
                    controlId="fracionado"
                  >
                    <h5 className="mb-2 text-body-highlight">Fracionado</h5>
                    <Form.Control
                      type="number"
                      min={1}
                      placeholder="Fracionado"
                      className="input-spin-none"
                      {...register("fracionado", {
                        required: "Campo obrigatório",
                        min: {
                          value: 1,
                          message: "O valor mínimo é 1",
                        },
                      })}
                      isInvalid={!!errors.fracionado}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.fracionado?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as="div"
                    className="col-6 col-lg-6"
                    controlId="unidadeMedida"
                  >
                    <h5 className="mb-2 text-body-highlight">Unidade Medida</h5>
                    <ReactSelect
                      isClearable
                      value={unidadeMedidaSelecionada}
                      onChange={(value: any) => {
                        setValue("idUnidadeMedida", value?.value);
                      }}
                      options={unidadeMedidaQuery.data?.map((d) => ({
                        value: d.id,
                        label: `${d.sigla} - ${d.descricao}`,
                      }))}
                      placeholder="Selecionar a unidade"
                    />
                  </Form.Group>
                </div>

                <table>
                  <thead>
                    <tr>
                      <th style={{ width: 200 }} />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-body-highlight fw-bold py-1">
                        Produto em estoque:
                      </td>
                      <td className="text-body-tertiary fw-semibold py-1">
                        R$1,090
                        <Button className="p-0 ms-1" type="button">
                          <FontAwesomeIcon
                            icon={faRotate}
                            className="text-body"
                            style={
                              { "--phoenix-text-opacity": 0.6 } as CSSProperties
                            }
                          />
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-body-highlight fw-bold py-1">
                        Última compra:
                      </td>
                      <td className="text-body-tertiary fw-semibold py-1">
                        30th June, 2021
                      </td>
                    </tr>
                    <tr>
                      <td className="text-body-highlight fw-bold py-1">
                        Preço última compra:
                      </td>
                      <td className="text-body-tertiary fw-semibold py-1">
                        5000
                      </td>
                    </tr>
                    <tr>
                      <td className="text-body-highlight fw-bold py-1">
                        Total de itens vendidos:
                      </td>
                      <td className="text-body-tertiary fw-semibold py-1">
                        20,000
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="controlado" className="h-100">
              <div className="d-flex flex-column h-100">
                <h5 className="mb-3 text-body-highlight">Controlado</h5>

                <div className="row g-3">
                  <Form.Group
                    as="div"
                    className="col-12 col-lg-6"
                    controlId={`controleEspecial`}
                  >
                    <h5 className="mb-2 text-body-highlight">
                      Sujeito a controle especial?
                    </h5>
                    <Form.Check
                      type="switch"
                      {...register(`controleEspecial`)}
                      defaultChecked={false}
                    />
                  </Form.Group>
                  <Form.Group
                    as="div"
                    className="col-12 col-lg-6"
                    controlId="rms"
                  >
                    <h5 className="mb-2 text-body-highlight">
                      Registro Ministério da Saúde
                    </h5>
                    <Form.Control
                      type="number"
                      min={1}
                      placeholder="Registro Ministério da Saúde"
                      className="input-spin-none"
                      {...register("rms")}
                      isInvalid={!!errors.rms}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.rms?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as="div"
                    className="col-12 col-lg-4"
                    controlId="rms"
                  >
                    <h5 className="mb-2 text-body-highlight">Receituario</h5>
                    <Form.Select className="mb-3" {...register("receituario")}>
                      {receituarios.map((r) => (
                        <option key={r.id} value={r.id}>
                          {r.descricao}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group
                    as="div"
                    className="col-12 col-lg-4"
                    controlId="rms"
                  >
                    <h5 className="mb-2 text-body-highlight">Lista</h5>
                    <Form.Select className="mb-3" {...register("lista")}>
                      {lista.map((l) => (
                        <option key={l.id} value={l.id}>
                          {l.descricao}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group
                    as="div"
                    className="col-12 col-lg-4"
                    controlId="rms"
                  >
                    <h5 className="mb-2 text-body-highlight">Tipo Controle</h5>
                    <Form.Select className="mb-3" {...register("tipoControle")}>
                      {tipoControle.map((l) => (
                        <option key={l.id} value={l.id}>
                          {l.descricao}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="atributos" className="h-100">
              <h5 className="mb-3 text-body-highlight">Atributos</h5>
              <Form.Check type="checkbox" id="inativo">
                <Form.Check.Input type="checkbox" {...register("inativo")} />
                <Form.Check.Label className="text-body fs-8">
                  Inativo
                </Form.Check.Label>
              </Form.Check>
              <Form.Group as="div" className="col-4 col-lg-4" controlId="rms">
                <h5 className="mb-2 text-body-highlight">PBM</h5>
                <Form.Select className="mb-3" {...register("pbm")}>
                  {pbm.map((l) => (
                    <option key={l.id} value={l.id}>
                      {l.descricao}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default InventoryTab;
