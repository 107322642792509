import { PageBreadcrumbItem } from "components/common/PageBreadcrumb";

export type TenantType = {
  id: number;
  codigo: number;
  nomeFantasia: string;
};

export type TenantQueryType = {
  itens: TenantType[];
  total: number;
};

export type TenantTableType = {
  id: number | undefined;
  codigo: number | undefined;
  nomeFantasia: string;
  razaoSocial: string;
  cnpj: string;
  inscricaoEstadual: string;
  regimeTributario: number;
};

export type EnderecoDtoType = {
  descricao: string;
  cep: string;
  logradouro: string;
  complemento?: string;
  bairro: string;
  municipio: string;
  uf: string;
  ibge: string;
  numero: string;
};

export const regimesTributarios = [
  { value: 0, label: "Simples Nacional" },
  { value: 1, label: "Lucro Presumido" },
  { value: 2, label: "Lucro Real" },
];

export type CriarTenantDtoType = {
  nomeFantasia: string;
  razaoSocial: string;
  cnpj: string;
  inscricaoEstadual: string;
  telefone: string;
  email: string;
  responsavel: string;
  regimeTributario: string;
  endereco: EnderecoDtoType;
  certificado?: {
    senha: string;
    arquivo: string;
  };
};

export type AlterarTenantDtoType = {
  codigo: number;
  nomeFantasia: string;
  razaoSocial: string;
  cnpj: string;
  inscricaoEstadual: string;
  telefone: string;
  email: string;
  responsavel: string;
  regimeTributario: string;
  endereco: EnderecoDtoType;
};

export const tenantsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: "Revenda",
    url: "#!",
  },
  {
    label: "Clientes",
    active: true,
  },
];

export const tenantBreadcrumbItems = (
  codigo?: number
): PageBreadcrumbItem[] => [
  {
    label: "Revenda",
    url: "#!",
  },
  {
    label: "Clientes",
    url: "/clientes",
  },
  {
    label: codigo?.toString() ?? "Nova",
    active: true,
  },
];
