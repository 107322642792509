import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Button from "components/base/Button";
import Section from "components/base/Section";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import AssinaturaTable from "components/tables/AssinaturaTable";
import { assinaturaBreadcrumbItems, ModuloItemType } from "data/assinatura";
import { useState, useEffect } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

const fetchModulos = async () => {
  const { data } = await axios.get("/api/admin/modulo");
  return data;
};

const fetchEmpresaModulos = async ({ queryKey }: any) => {
  const [, id] = queryKey;
  const { data } = await axios.get(`/api/admin/empresa/${id}/contrato`);
  return data;
};

export default function Assinatura() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: empresaModulos, isLoading: loadingEmpresaModulos } = useQuery({
    queryKey: ["empresaModulos", id],
    queryFn: fetchEmpresaModulos,
  });

  const { data: modulos, isLoading: loadingModulos } = useQuery({
    queryKey: ["modulos"],
    queryFn: fetchModulos,
  });

  const [selectedProducts, setSelectedProducts] = useState<ModuloItemType[]>(
    []
  );

  // Atualiza os módulos selecionados quando os dados da empresa são carregados
  useEffect(() => {
    if (empresaModulos) {
      const initialSelectedProducts = empresaModulos
        .map((mod: any) => mod.modulos)
        .flat();
      setSelectedProducts(initialSelectedProducts);
    }
  }, [empresaModulos]);

  // Função para adicionar ou remover módulos da seleção
  const toggleProduct = (product: ModuloItemType) => {
    const isSelected = selectedProducts.some((p) => p.id === product.id);
    if (isSelected) {
      // Remove o módulo se já estiver selecionado
      setSelectedProducts(selectedProducts.filter((p) => p.id !== product.id));
    } else {
      // Adiciona o módulo se não estiver selecionado
      setSelectedProducts([...selectedProducts, product]);
    }
  };

  const mutation = useMutation({
    mutationFn: (data: any) => {
      return axios.post(`/api/admin/empresa/${id}/contrato`, data);
    },
  });

  const handleSalvar = async () => {
    await mutation.mutateAsync(selectedProducts.map((p) => p.id));

    navigate(`/clientes/${id}`);
  };

  if (loadingModulos || loadingEmpresaModulos)
    return (
      <Spinner animation="border" role="status" className="d-block mx-auto">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );

  return (
    <div className="pt-5 mb-9">
      <Section small className="py-0">
        <PageBreadcrumb items={assinaturaBreadcrumbItems(id)} />
        <h2 className="mb-6">Assinatura</h2>
        <Row className="g-5">
          <Col>
            {empresaModulos?.map((empresa: any) => {
              // Criamos uma lista combinando todos os módulos com os módulos já ativados na empresa
              const modulosCombinados = modulos?.map((modulo: any) => {
                // Verificamos se o módulo já está ativo na empresa
                const isAtivado = empresa.modulos.some(
                  (m: any) => m.id === modulo.id
                );

                // Retornamos o módulo com a propriedade "selected"
                return {
                  ...modulo,
                  selected: isAtivado, // Marque como true se estiver ativado
                };
              });

              return (
                <>
                  <h5 className="mb-4">{empresa.razaoSocial}</h5>
                  <AssinaturaTable
                    products={modulosCombinados} // Passamos todos os módulos disponíveis
                    addProduct={(product: ModuloItemType) => {
                      toggleProduct(product); // Função para alternar a seleção do módulo
                    }}
                    removeProduct={(product: ModuloItemType) => {
                      toggleProduct(product); // Função para alternar a remoção do módulo
                    }}
                    selectedProducts={selectedProducts} // Passa os produtos selecionados
                  />
                </>
              );
            })}
          </Col>
        </Row>
        <Col xs={12} className="mt-5 d-flex justify-content-end gap-3">
          <Button
            variant="phoenix-secondary"
            className="text-nowrap"
            type="button"
            as={Link}
            to={id ? `/clientes/${id}` : "/clientes"}
          >
            Sair sem salvar
          </Button>
          <Button
            variant="primary"
            className="px-8 px-sm-11 me-2"
            loadingPosition="end"
            onClick={handleSalvar}
            loading={mutation.isPending}
          >
            Salvar
          </Button>
        </Col>
      </Section>
    </div>
  );
}
