import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Button from "components/base/Button";
import Money from "components/base/InputMoney";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import {
  AlterarFabricanteDtoType,
  fabricanteBreadcrumbItems,
} from "data/fabricantes";
import {
  AlterarRegraFiscalDtoType,
  optionsCsosn,
  optionsCstIcms,
  optionsCstPis,
  optionsOrigemMercadoria,
  regraFiscalBreadcrumbItems,
} from "data/regraFiscal";

import { transformErrors } from "helpers/utils";
import { useEffect } from "react";
import { Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import NcmModal from "../novaRegraFiscal/ncm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useConfirmation } from "hooks/useConfirmation";

const fetchCfops = async () => {
  const { data } = await axios.get("/api/regraFiscal/cfop");
  return data;
};

const EditarRegraFiscal = () => {
  const { id } = useParams();

  const cfopQuery = useQuery({
    queryKey: ["cfops"],
    queryFn: fetchCfops,
  });

  const ncmConfirmation = useConfirmation<{ key: string; value: string }>();

  const { isLoading, isError, data } = useQuery<{
    data: AlterarRegraFiscalDtoType;
  }>({
    queryKey: ["regraFiscal", id],
    queryFn: () => axios.get(`/api/regraFiscal/${id}`),
    enabled: !!id,
  });

  const mutation = useMutation<
    any,
    any,
    { id: number; data: AlterarRegraFiscalDtoType }
  >({
    mutationFn: ({ id, data }) => axios.put(`/api/regraFiscal/${id}`, data),
    onError: (error) => {
      const formattedErrors = transformErrors(error.response.data.errors);
      for (const [field, error] of Object.entries(formattedErrors)) {
        form.setError(field as any, error as any);
      }
    },
  });

  const regraFiscal = data?.data;

  const form = useForm<AlterarRegraFiscalDtoType>();

  useEffect(() => {
    if (regraFiscal) {
      console.log(regraFiscal);
      form.reset(regraFiscal);
    }
  }, [regraFiscal]);

  if (isLoading)
    return (
      <Spinner animation="border" role="status" className="d-block mx-auto">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );

  if (isError) {
    return <div>Ocorreu um erro ao carregar os dados</div>;
  }

  if (!regraFiscal) {
    return <div>Regra fiscal não encontrada</div>;
  }

  const onSubmit: SubmitHandler<AlterarRegraFiscalDtoType> = (data) => {
    mutation.mutate({ data, id: parseInt(id as string) });
  };

  if (mutation.isSuccess) {
    return <Navigate to={`/retaguarda/cadastros/regras-fiscais`} />;
  }

  const handleAbrirModalNcm = async () => {
    try {
      const r = await ncmConfirmation.requestConfirmation();
      form.setValue("ncm", r.key);
    } catch (error) {}
  };

  return (
    <div>
      {ncmConfirmation.isShow && <NcmModal {...ncmConfirmation} />}
      <FormProvider {...form}>
        <PageBreadcrumb
          items={regraFiscalBreadcrumbItems(regraFiscal?.codigo)}
        />
        <Form
          noValidate
          className="mb-9"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
            <div>
              <h2 className="mb-2">Editar uma regra fiscal</h2>
              <h5 className="text-body-tertiary fw-semibold">
                Atualize as informações da regra fiscal
              </h5>
            </div>
            <div className="d-flex flex-wrap gap-2">
              <Button
                variant="phoenix-secondary"
                type="button"
                onClick={() => form.reset()}
              >
                Descartar
              </Button>
              <Button
                variant="primary"
                type="submit"
                loading={mutation.isPending}
                disabled={mutation.isPending}
                loadingPosition="start"
              >
                Salvar
              </Button>
            </div>
          </div>
          <Row className="g-5">
            <Col xs={12} xl={8}>
              <h4 className="mb-3">Informações da regra fiscal</h4>
              <Row className="g-2">
                <Form.Group
                  as={Col}
                  xs={2}
                  xl={2}
                  className="mb-5"
                  controlId="codigo"
                >
                  <Form.Control disabled value={regraFiscal.codigo} required />
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={12}
                  xl={10}
                  className="mb-5"
                  controlId="descricao"
                >
                  <Form.Control
                    placeholder="Descrição da regra fiscal"
                    {...form.register("descricao")}
                    isInvalid={!!form.formState.errors.descricao}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.descricao?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <h4 className="mb-3">Classificação Fiscal</h4>
              <Row className="g-2">
                <Form.Group as={Col} xs={12} xl={4} controlId="cfop">
                  <Form.Label htmlFor="cfop">CFOP</Form.Label>
                  <Form.Select
                    {...form.register("cfop")}
                    isInvalid={!!form.formState.errors.cfop}
                  >
                    <option value="">Selecione um CFOP</option>
                    {cfopQuery.data?.map((cfop: any) => (
                      <option key={cfop.key} value={cfop.key}>
                        {cfop.value}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.cfop?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={12} xl={4} controlId="ncm">
                  <Form.Label htmlFor="ncm">NCM</Form.Label>
                  <InputGroup>
                    <Form.Control
                      placeholder="NCM"
                      {...form.register("ncm")}
                      isInvalid={!!form.formState.errors.ncm}
                    />
                    <Button
                      variant="phoenix-secondary"
                      onClick={handleAbrirModalNcm}
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.ncm?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={12} xl={2} controlId="cest">
                  <Form.Label htmlFor="cest">CEST</Form.Label>
                  <Form.Control
                    placeholder="CEST"
                    {...form.register("cest")}
                    isInvalid={!!form.formState.errors.cest}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.cest?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={12}
                  xl={2}
                  controlId="codigoBeneficioFiscal"
                >
                  <Form.Label htmlFor="codigoBeneficioFiscal">
                    Código de Benefício Fiscal
                  </Form.Label>
                  <Form.Control
                    placeholder="Código do benefício fiscal"
                    {...form.register("codigoBeneficioFiscal")}
                    isInvalid={!!form.formState.errors.codigoBeneficioFiscal}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.codigoBeneficioFiscal?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={12}
                  xl={4}
                  controlId="origemMercadoria"
                  className="mb-5"
                >
                  <Form.Label htmlFor="origemMercadoria">
                    Origem da Mercadoria
                  </Form.Label>
                  <Form.Select
                    {...form.register("origemMercadoria")}
                    isInvalid={!!form.formState.errors.origemMercadoria}
                  >
                    <option value="">Selecione a origem da mercadoria</option>
                    {optionsOrigemMercadoria.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.origemMercadoria?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <h4 className="mb-3">ICMS</h4>
              <Row className="g-2">
                <Form.Group as={Col} xs={12} xl={4} controlId="cst">
                  <Form.Label htmlFor="cst">CST</Form.Label>
                  <Form.Select
                    {...form.register("cst")}
                    isInvalid={!!form.formState.errors.cst}
                  >
                    <option value="">Selecione um CST</option>
                    {optionsCstIcms.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.cst?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={12} xl={4} controlId="csosn">
                  <Form.Label htmlFor="csosn">CSOSN</Form.Label>
                  <Form.Select
                    {...form.register("csosn")}
                    isInvalid={!!form.formState.errors.csosn}
                  >
                    <option value="">Selecione um CSOSN</option>
                    {optionsCsosn.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.csosn?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={12} xl={2} controlId="aliquota">
                  <Form.Label htmlFor="aliquota">Alíquota</Form.Label>
                  <Controller
                    name={`aliquota`}
                    control={form.control}
                    render={({ field }) => (
                      <Money
                        placeholder="Aliquota ICMS"
                        {...field}
                        isInvalid={!!form.formState.errors.aliquota}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.aliquota?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={12} xl={2} controlId="redBc">
                  <Form.Label htmlFor="redBc">Redução Base</Form.Label>
                  <Controller
                    name={`redBc`}
                    control={form.control}
                    render={({ field }) => (
                      <Money
                        placeholder="Redução Base"
                        {...field}
                        isInvalid={!!form.formState.errors.redBc}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.redBc?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={12} xl={2} controlId="fcp">
                  <Form.Label htmlFor="fcp">FCP</Form.Label>
                  <Controller
                    name={`fcp`}
                    control={form.control}
                    render={({ field }) => (
                      <Money
                        placeholder="FCP"
                        {...field}
                        isInvalid={!!form.formState.errors.fcp}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.fcp?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={12}
                  xl={2}
                  controlId="diferimento"
                  className="mb-5"
                >
                  <Form.Label htmlFor="diferimento">diferimento</Form.Label>
                  <Controller
                    name={`diferimento`}
                    control={form.control}
                    render={({ field }) => (
                      <Money
                        placeholder="Diferimento"
                        {...field}
                        isInvalid={!!form.formState.errors.diferimento}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.diferimento?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <h4 className="mb-3">Substituição Tributária</h4>
              <Row className="g-2">
                <Form.Group as={Col} xs={12} xl={2} controlId="aliquotaSt">
                  <Form.Label htmlFor="aliquotaSt">Alíquota ST</Form.Label>
                  <Controller
                    name={`aliquotaSt`}
                    control={form.control}
                    render={({ field }) => (
                      <Money
                        placeholder="Aliquota ST"
                        {...field}
                        isInvalid={!!form.formState.errors.aliquotaSt}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.aliquotaSt?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={12} xl={2} controlId="mva">
                  <Form.Label htmlFor="mva">MVA</Form.Label>
                  <Controller
                    name={`mva`}
                    control={form.control}
                    render={({ field }) => (
                      <Money
                        placeholder="MVA"
                        {...field}
                        isInvalid={!!form.formState.errors.mva}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.mva?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={12}
                  xl={2}
                  controlId="redBcSt"
                  className="mb-5"
                >
                  <Form.Label htmlFor="redBcSt">Redução Base ST</Form.Label>
                  <Controller
                    name={`redBcSt`}
                    control={form.control}
                    render={({ field }) => (
                      <Money
                        placeholder="Redução Base ST"
                        {...field}
                        isInvalid={!!form.formState.errors.redBcSt}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.redBcSt?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <h4 className="mb-3">PIS COFINS</h4>
              <Row className="g-2">
                <Form.Group as={Col} xs={12} xl={4} controlId="aliquotaPis">
                  <Form.Label htmlFor="aliquotaPis">Alíquota PIS</Form.Label>
                  <Controller
                    name={`aliquotaPis`}
                    control={form.control}
                    render={({ field }) => (
                      <Money
                        placeholder="Aliquota ST"
                        {...field}
                        isInvalid={!!form.formState.errors.aliquotaPis}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.aliquotaPis?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={12} xl={4} controlId="cstPis">
                  <Form.Label htmlFor="cstPis">CST PIS</Form.Label>
                  <Form.Select
                    {...form.register("cstPis")}
                    isInvalid={!!form.formState.errors.cstPis}
                  >
                    <option value="">Selecione um CST</option>
                    {optionsCstPis.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.cstPis?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={12} xl={4} controlId="aliquotaCofins">
                  <Form.Label htmlFor="aliquotaCofins">
                    Alíquota COFINS
                  </Form.Label>
                  <Controller
                    name={`aliquotaCofins`}
                    control={form.control}
                    render={({ field }) => (
                      <Money
                        placeholder="Alíquota ST"
                        {...field}
                        isInvalid={!!form.formState.errors.aliquotaCofins}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.aliquotaCofins?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={12} xl={4} controlId="cstCofins">
                  <Form.Label htmlFor="cstCofins">CST COFINS</Form.Label>
                  <Form.Select
                    {...form.register("cstCofins")}
                    isInvalid={!!form.formState.errors.cstCofins}
                  >
                    <option value="">Selecione um CST</option>
                    {optionsCstPis.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.cstCofins?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </div>
  );
};

export default EditarRegraFiscal;
