import { PageBreadcrumbItem } from "components/common/PageBreadcrumb";

export type ProdutoResumoType = {
  total: number;
  ativos: number;
  promocao: number;
  desconto: number;
};

export type DepartamentoType = {
  id: number;
  codigo: number;
  descricao: string;
};

export type CategoriaType = {
  id: number;
  codigo: number;
  descricao: string;
};

export type PrincipioAtivoType = {
  id: number;
  codigo: number;
  descricao: string;
};

export type FabricanteType = {
  id: number;
  codigo: number;
  descricao: string;
};

export type ProdutoTableType = {
  id: number | undefined;
  codigo: number | undefined;
  descricao: string;
  codigoBarras: string;
  fabricante: string;
  principioAtivo: string;
  departamento: string;
  estoque: number;
  categorias: string[];
};

export type ProdutoEmpresaType = {
  idEmpresa: number;
  idRegraFiscal: number | undefined;
  valor: number;
  precoCompra: number;
  margem: number;
  descontoMaximo: number | undefined;
  desconto: number | undefined;
  estoqueMinimo: number;
  precoAberto: boolean;
};

export type CriarProdutoEmpresaDtoType = {
  idEmpresa: number;
  idRegraFiscal?: number | undefined;
  valor: number;
  precoCompra: number;
  margem: number;
  descontoMaximo: number | undefined;
  desconto: number | undefined;
  estoqueMinimo: number;
  precoAberto: boolean;
};

export const receituarios = [
  { id: 0, descricao: "Receita branca" },
  { id: 1, descricao: "Notificação azul" },
  { id: 2, descricao: "Notificação Branca" },
  { id: 3, descricao: "Notificação Amarela" },
  { id: 4, descricao: "Receita Antimicrobiano em 2 vias" },
];

export const lista = [
  { id: 0, descricao: "A1" },
  { id: 1, descricao: "A2" },
  { id: 2, descricao: "A3" },
  { id: 3, descricao: "A4" },
  { id: 4, descricao: "B1" },
  { id: 5, descricao: "B2" },
  { id: 6, descricao: "C1" },
  { id: 7, descricao: "C2" },
  { id: 8, descricao: "C3" },
  { id: 9, descricao: "C4" },
  { id: 10, descricao: "C5" },
  { id: 11, descricao: "D1" },
  { id: 12, descricao: "D2" },
  { id: 13, descricao: "F1" },
  { id: 14, descricao: "F2" },
  { id: 15, descricao: "F3" },
  { id: 16, descricao: "F4" },
];

export const tipoControle = [
  { id: 0, descricao: "Antimicrobiano" },
  { id: 1, descricao: "Sujeito a controle especial" },
];

export const pbm = [
  { id: 0, descricao: "Nenhum" },
  { id: 1, descricao: "FuncionalCard" },
  { id: 2, descricao: "EPharma" },
  { id: 3, descricao: "Vidalink" },
];

export type CriarProdutoDtoType = {
  idFabricante: number | undefined;
  idDepartamento?: number | undefined;
  descricao: string;
  fracionado: number;
  idUnidadeMedida?: number | undefined;
  controleEspecial: boolean;
  rms: string;
  pmc?: number | undefined;
  idPrincipioAtivo?: number | undefined;
  codigosDeBarras: string[];
  inativo: boolean;
  lista: string;
  tipoControle: string;
  receituario: string;
  empresas: CriarProdutoEmpresaDtoType[];
  idsCategorias: number[];
  pbm: string;
};

export type EditarProdutoEmpresaDtoType = {
  idEmpresa: number;
  idRegraFiscal?: number | undefined;
  valor: number;
  precoCompra: number;
  margem: number;
  descontoMaximo: number | undefined;
  desconto: number | undefined;
  estoqueMinimo: number;
  precoAberto: boolean;
};

export type EditarProdutoDtoType = {
  codigo: number;
  idFabricante: number | undefined;
  idDepartamento?: number | undefined;
  descricao: string;
  fracionado: number;
  idUnidadeMedida?: number | undefined;
  controleEspecial: boolean;
  rms: string;
  pmc?: number | undefined;
  idPrincipioAtivo?: number | undefined;
  codigosDeBarras: string[];
  inativo: boolean;
  lista: string;
  tipoControle: string;
  receituario: string;
  empresas: EditarProdutoEmpresaDtoType[];
  idsCategorias: number[];
  pbm: string;
};

export const produtosBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: "Retaguarda",
    url: "#!",
  },
  {
    label: "Cadastros",
    url: "#!",
  },
  {
    label: "Produtos",
    active: true,
  },
];

export const produtoBreadcrumbItems = (
  codigo?: number
): PageBreadcrumbItem[] => [
  {
    label: "Retaguarda",
    url: "#!",
  },
  {
    label: "Cadastros",
    url: "#!",
  },
  {
    label: "Produtos",
    url: "/retaguarda/cadastros/produtos",
  },
  {
    label: codigo?.toString() ?? "Novo",
    active: true,
  },
];
