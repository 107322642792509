import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import RevealDropdown, {
  RevealDropdownTrigger,
} from "components/base/RevealDropdown";
import ActionDropdownItems from "components/common/ActionDropdownItems";
import { EmpresaTableType, regimesTributarios } from "data/empresa";
import { currencyFormat } from "helpers/utils";
import { Link } from "react-router-dom";

export const empresaTableColumns: ColumnDef<EmpresaTableType>[] = [
  {
    accessorKey: "codigo",
    header: "Código",
    meta: {
      headerProps: { style: { width: 100 }, className: "ps-4" },
      cellProps: {
        className: "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85",
      },
    },
  },
  {
    accessorKey: "razaoSocial",
    header: "Razão Social",
    cell: ({ row: { original } }) => {
      const { razaoSocial, id } = original;
      return (
        <Link
          to={`/retaguarda/cadastros/empresas/${id}`}
          className="fw-semibold line-clamp-3"
        >
          {razaoSocial}
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: 350 }, className: "ps-4" },
      cellProps: { className: "ps-4" },
    },
  },
  {
    accessorKey: "nomeFantasia",
    header: "Nome Fantasia",
    meta: {
      headerProps: { style: { width: 350 }, className: "ps-4" },
      cellProps: {
        className: "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85",
      },
    },
  },
  {
    accessorKey: "cnpj",
    header: "CNPJ",
    meta: {
      headerProps: { style: { width: 150 }, className: "ps-4" },
      cellProps: {
        className: "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85",
      },
    },
  },
  {
    accessorKey: "inscricaoEstadual",
    header: "Inscrição Estadual",
    meta: {
      headerProps: { style: { width: 150 }, className: "ps-4" },
      cellProps: {
        className: "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85",
      },
    },
  },
  {
    accessorKey: "regimeTributario",
    header: "Regime Tributário",
    cell: ({ row: { original } }) => {
      const { regimeTributario } = original;

      const regime = regimesTributarios.find(
        (r) => r.value === regimeTributario
      );

      return (
        <Badge className="text-body-tertiary" variant="tag">
          {regime?.label}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: 150 }, className: "ps-4" },
      cellProps: {
        className: "fs-9 fw-semibold ps-4 text-body-tertiary text-opacity-85",
      },
    },
  },
  {
    id: "action",
    cell: () => (
      <RevealDropdownTrigger>
        <RevealDropdown>
          <ActionDropdownItems />
        </RevealDropdown>
      </RevealDropdownTrigger>
    ),
    meta: {
      headerProps: { style: { width: "7%" } },
      cellProps: { className: "text-end" },
    },
  },
];

const EmpresaTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination showViewAllBtn={false} />
    </div>
  );
};

export default EmpresaTable;
