import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Money from "components/base/InputMoney";
import Scrollbar from "components/base/Scrollbar";
import { EmpresaQueryType, EmpresaType } from "data/empresa";
import { CriarProdutoDtoType, EditarProdutoDtoType } from "data/produto";
import { floatFormat } from "helpers/utils";
import { ChangeEvent } from "react";
import { Form, Table } from "react-bootstrap";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";

interface ProdutoEmpresaPrecosTableProps {}

const fetchEmpresas = async () => {
  const { data } = await axios.get("/api/empresa");
  return data;
};

const ProdutoEmpresaPrecosTable = ({}: ProdutoEmpresaPrecosTableProps) => {
  const empresasQuery = useQuery<EmpresaQueryType>({
    queryKey: ["empresas"],
    queryFn: fetchEmpresas,
  });

  const { control, watch } = useFormContext<
    CriarProdutoDtoType | EditarProdutoDtoType
  >();

  const { fields } = useFieldArray({
    control,
    name: "empresas",
    rules: { required: true },
  });

  return (
    <Scrollbar autoHeight autoHeightMax="100%" className="table-scrollbar">
      <Table className="phoenix-table fs-9 mb-0 border-top border-translucent">
        <thead>
          <tr>
            <th scope="col">Empresa</th>
            <th className="text-center" scope="col">
              Custo
            </th>
            <th className="text-center" scope="col">
              Margem
            </th>
            <th className="text-center" scope="col">
              Venda
            </th>
            <th className="text-center" scope="col">
              Desc Auto
            </th>
            <th className="text-center" scope="col">
              Desc Máximo
            </th>
            <th className="text-center" scope="col">
              Estoque Mínimo
            </th>
            <th className="text-center" scope="col" style={{ width: "10px" }}>
              Preço Aberto
            </th>
          </tr>
        </thead>
        {empresasQuery.isSuccess && empresasQuery.data.itens.length > 0 && (
          <tbody className="list" id="cart-table-body">
            {empresasQuery.data.itens.map(
              (empresa: EmpresaType, index: number) => (
                <ProdutoEmpresaPrecosRow
                  key={fields[index]?.id}
                  index={index}
                  empresa={empresa}
                />
              )
            )}
          </tbody>
        )}
      </Table>
    </Scrollbar>
  );
};

const ProdutoEmpresaPrecosRow = ({
  empresa: { nomeFantasia, id },
  index,
}: {
  empresa: EmpresaType;
  index: number;
}) => {
  const {
    register,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useFormContext<CriarProdutoDtoType | EditarProdutoDtoType>();

  return (
    <tr className="cart-table-row">
      <td>
        <Link className="fw-semibold line-clamp-2" to="#!">
          {nomeFantasia}
        </Link>
      </td>
      <input
        type="hidden"
        {...register(`empresas.${index}.idEmpresa`, {
          required: "Campo obrigatório",
        })}
        value={id}
      />
      <Form.Group
        as="td"
        className="text-center"
        controlId={`empresas.${index}.precoCompra`}
      >
        <Controller
          name={`empresas.${index}.precoCompra`}
          control={control}
          render={({ field }) => (
            <Money
              {...field}
              isInvalid={!!errors?.empresas?.[index]?.precoCompra}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                field.onChange(e);
                const precoCompra = floatFormat(e.target.value);
                const margem = floatFormat(watch(`empresas.${index}.margem`));
                const valor = precoCompra * (1 + margem / 100);
                setValue(`empresas.${index}.valor`, valor);
              }}
            />
          )}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.empresas?.[index]?.precoCompra?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group
        as="td"
        className="text-center"
        controlId={`empresas.${index}.margem`}
      >
        <Controller
          name={`empresas.${index}.margem`}
          control={control}
          render={({ field }) => (
            <Money
              {...field}
              isInvalid={!!errors?.empresas?.[index]?.margem}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                field.onChange(e);
                const precoCompra = floatFormat(
                  watch(`empresas.${index}.precoCompra`)
                );
                const margem = floatFormat(e.target.value);
                const valor = precoCompra * (1 + margem / 100);

                setValue(`empresas.${index}.valor`, valor);
              }}
            />
          )}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.empresas?.[index]?.margem?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group
        as="td"
        className="text-center"
        controlId={`empresas.${index}.valor`}
      >
        <Controller
          name={`empresas.${index}.valor`}
          control={control}
          render={({ field }) => (
            <Money
              {...field}
              isInvalid={!!errors?.empresas?.[index]?.valor}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                field.onChange(e);
                //calcular a margem
                const precoCompra = floatFormat(
                  watch(`empresas.${index}.precoCompra`)
                );
                const valor = floatFormat(e.target.value);
                const margem = ((valor - precoCompra) / precoCompra) * 100;
                setValue(`empresas.${index}.margem`, margem);
              }}
            />
          )}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.empresas?.[index]?.valor?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group
        as="td"
        className="text-center"
        controlId={`empresas.${index}.desconto`}
      >
        <Controller
          name={`empresas.${index}.desconto`}
          control={control}
          render={({ field }) => (
            <Money
              {...field}
              isInvalid={!!errors?.empresas?.[index]?.desconto}
            />
          )}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.empresas?.[index]?.desconto?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group
        as="td"
        className="text-center"
        controlId={`empresas.${index}.descontoMaximo`}
      >
        <Controller
          name={`empresas.${index}.descontoMaximo`}
          control={control}
          render={({ field }) => (
            <Money
              {...field}
              isInvalid={!!errors?.empresas?.[index]?.descontoMaximo}
            />
          )}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.empresas?.[index]?.descontoMaximo?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group
        as="td"
        className="text-center"
        controlId={`empresas.${index}.estoqueMinimo`}
      >
        <Form.Control
          type="text"
          {...register(`empresas.${index}.estoqueMinimo`)}
          isInvalid={!!errors?.empresas?.[index]?.estoqueMinimo}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.empresas?.[index]?.estoqueMinimo?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group
        as="td"
        className="text-center"
        controlId={`empresas.${index}.precoAberto`}
      >
        <Form.Check
          type="switch"
          {...register(`empresas.${index}.precoAberto`)}
          isInvalid={!!errors?.empresas?.[index]?.precoAberto}
          defaultChecked={false}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.empresas?.[index]?.precoAberto?.message}
        </Form.Control.Feedback>
      </Form.Group>
    </tr>
  );
};

export default ProdutoEmpresaPrecosTable;
