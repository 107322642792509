import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import Button from "components/base/Button";
import Dropzone from "components/base/Dropzone";
import { useRef, useState } from "react";
import { Modal } from "react-bootstrap";

const ImportModal = ({
  onConfirm,
  onCancel,
  isShow,
}: {
  onConfirm: (d: any) => void;
  onCancel: () => void;
  isShow: boolean;
}) => {
  const [value, setValue] = useState<any>();
  const cancelTokenSource = useRef<any>(null); // Usando ref para manter a referência do cancel token

  const mutation = useMutation({
    mutationFn: async (file: any) => {
      const CancelToken = axios.CancelToken;
      cancelTokenSource.current = CancelToken.source(); // Armazena o source

      const formData = new FormData();
      formData.append("file", file);

      const { data } = await axios.post("/api/produto/importar", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        cancelToken: cancelTokenSource.current.token, // Usa o token armazenado
      });

      return data;
    },
    onSuccess: () => {
      setValue(undefined);
      onConfirm({});
    },
    onError: () => {
      // Lidar com erro, se necessário
    },
  });

  const handleExit = () => {
    setValue(undefined);

    // Cancela a requisição se estiver em andamento
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Requisição cancelada pelo usuário.");
    }

    onCancel();
  };

  return (
    <Modal
      show={isShow}
      onHide={handleExit}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>Importar produtos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Dropzone
          onDrop={async (acceptedFiles) => {
            setValue(acceptedFiles[0]);
          }}
          accept={{
            "text/csv": [".csv"],
            "text/plain": [".txt"],
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="phoenix-secondary" onClick={handleExit}>
          Fechar
        </Button>
        <Button
          variant="primary"
          onClick={() => mutation.mutate(value)}
          loading={mutation.isPending}
          disabled={mutation.isPending}
          loadingPosition="start"
        >
          Importar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportModal;
