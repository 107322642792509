import { PageBreadcrumbItem } from "components/common/PageBreadcrumb";

export type FornecedorMenuType = {
  id: number;
  razaoSocial: string;
};

export type EntradaDeNotaTableType = {
  id: number;
  numero: number;
  fornecedor: string;
  fornecedorId: number;
  valor: number;
  emissao: string;
};

export type EntradaDeNotaDetTableType = {
  id: number;
  xProd: string;
  nItem: number;
  produtoId: number | null;
  vDesc: number;
  cEAN: string;
  qCom: number;
  vProd: number;
  vUnCom: number;
  custo: number;
};

export const entradaDeNotasBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: "Retaguarda",
    url: "#!",
  },
  {
    label: "Operacional",
    url: "#!",
  },
  {
    label: "Entrada de Notas",
    active: true,
  },
];

export const entradaDeNotaBreadcrumbItems = (
  numero: number
): PageBreadcrumbItem[] => [
  {
    label: "Retaguarda",
    url: "#!",
  },
  {
    label: "Operacional",
    url: "#!",
  },
  {
    label: "Entrada de Notas",
    url: "/retaguarda/operacional/entrada-de-notas",
  },
  {
    label: numero.toString(),
    active: true,
  },
];
