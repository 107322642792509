import { PageBreadcrumbItem } from "components/common/PageBreadcrumb";

export type UnidadeMedidaType = {
  id: number;
  sigla: string;
  descricao: string;
};

export type CriarUnidadeMedidaDtoType = {
  sigla: string;
  descricao: string;
};

export type AlterarUnidadeMedidaDtoType = {
  sigla: string;
  descricao: string;
};

export type UnidadeMedidaTableType = {
  id: number;
  sigla: string;
  descricao: string;
};

export const unidadesDeMedidasBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: "Retaguarda",
    url: "#!",
  },
  {
    label: "Cadastros",
    url: "#!",
  },
  {
    label: "Unidades de Medida",
    active: true,
  },
];

export const unidadeDeMedidaBreadcrumbItems = (
  sigla?: string
): PageBreadcrumbItem[] => [
  {
    label: "Retaguarda",
    url: "#!",
  },
  {
    label: "Cadastros",
    url: "#!",
  },
  {
    label: "Unidades de Medida",
    url: "/retaguarda/cadastros/unidades-de-medida",
  },
  {
    label: sigla ?? "Nova",
    active: true,
  },
];
