import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import Button from "components/base/Button";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import { transformErrors } from "helpers/utils";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import {
  CriarDepartamentoDtoType,
  departamentoBreadcrumbItems,
} from "data/departamentos";
import DepartamentoEmpresaTable from "components/tables/DepartamentoEmpresaTable";

const NovoDepartamento = () => {
  const form = useForm<CriarDepartamentoDtoType>({
    defaultValues: {
      descricao: "",
    },
  });

  const mutation = useMutation<any, any, CriarDepartamentoDtoType>({
    mutationFn: (p: CriarDepartamentoDtoType) => {
      return axios.post("/api/departamento", p);
    },
    onError: (error) => {
      const formattedErrors = transformErrors(error.response.data.errors);
      for (const [field, error] of Object.entries(formattedErrors)) {
        form.setError(field as any, error as any);
      }
    },
  });

  const onSubmit: SubmitHandler<CriarDepartamentoDtoType> = (data) => {
    mutation.mutate(data);
  };

  if (mutation.isSuccess) {
    const id = mutation.data.data.id;
    return <Navigate to={`/retaguarda/cadastros/departamentos/${id}`} />;
  }

  return (
    <div>
      <FormProvider {...form}>
        <PageBreadcrumb items={departamentoBreadcrumbItems()} />
        <Form
          noValidate
          className="mb-9"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
            <div>
              <h2 className="mb-2">Cadastrar um novo departamento</h2>
              <h5 className="text-body-tertiary fw-semibold">
                Preencha as informações para cadastrar um novo departamento
              </h5>
            </div>
            <div className="d-flex flex-wrap gap-2">
              <Button variant="phoenix-secondary" type="button">
                Descartar
              </Button>
              <Button
                variant="primary"
                type="submit"
                loading={mutation.isPending}
                disabled={mutation.isPending}
                loadingPosition="start"
              >
                Salvar
              </Button>
            </div>
          </div>
          <Row className="g-5">
            <Col xs={12} xl={8}>
              <h4 className="mb-3">Informações do departamento</h4>
              <Row className="g-2">
                <Form.Group
                  as={Col}
                  xs={12}
                  xl={12}
                  className="mb-5"
                  controlId="descricao"
                >
                  <Form.Control
                    placeholder="Descrição do departamento"
                    {...form.register("descricao")}
                    required
                    isInvalid={!!form.formState.errors.descricao}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.descricao?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <div className="mb-5">
                <h4 className="mb-3">Departamento por empresa</h4>
                <DepartamentoEmpresaTable />
              </div>
            </Col>
            <Col xs={12} xl={4}></Col>
          </Row>
        </Form>
      </FormProvider>
    </div>
  );
};

export default NovoDepartamento;
