import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import ResetPasswordForm from "components/modules/auth/ResetPasswordForm";
import AuthSimpleLayout from "layouts/AuthSimpleLayout";
import { useAuth } from "providers/AuthProvider";
import { useState } from "react";
import { Navigate } from "react-router-dom";

const postResetPassword = async (data: any) => {
  return await axios.post("/api/user/respond-to-new-password-challenge", data);
};

const ResetPassword = () => {
  const { user, setUser } = useAuth();
  const [validationError, setValidationError] = useState<string | null>(null);
  const { isPending, data, mutate, error } = useMutation<
    any,
    any,
    {
      email: string;
      newPassword: string;
      session: string;
    }
  >({
    mutationFn: postResetPassword,
  });

  const validatePassword = (e: React.FormEvent<HTMLFormElement>) => {
    const password = e.currentTarget.password.value;
    const confirmPassword = e.currentTarget.confirmPassword.value;

    if (password !== confirmPassword) {
      setValidationError("As senhas não coincidem");
      return false;
    } else {
      setValidationError(null);
      return true;
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validatePassword(e)) {
      const newPassword = e.currentTarget.password.value;

      await mutate({
        email: user.email,
        newPassword,
        session: user.session,
      });
    }
  };

  if (data?.data?.user) {
    setUser(data.data.user);
    return <Navigate to="/" replace />;
  }

  return (
    <AuthSimpleLayout>
      <ResetPasswordForm
        onSubmit={handleSubmit}
        validationError={error?.response?.data?.error}
        loading={isPending}
      />
    </AuthSimpleLayout>
  );
};

export default ResetPassword;
