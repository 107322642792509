import Scrollbar from "components/base/Scrollbar";
import { ModuloItemType } from "data/assinatura";
import { ChangeEvent } from "react";
import { Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

interface AssinaturaTableProps {
  products: ModuloItemType[];
  addProduct: (product: ModuloItemType) => void;
  removeProduct: (product: ModuloItemType) => void;
  selectedProducts: ModuloItemType[]; // Adicionando os produtos selecionados como propriedade
}

const AssinaturaTable = ({
  products,
  addProduct,
  removeProduct,
  selectedProducts, // Recebemos os produtos selecionados
}: AssinaturaTableProps) => {
  return (
    <Scrollbar autoHeight autoHeightMax="100%" className="table-scrollbar">
      <Table className="phoenix-table fs-9 mb-0 border-top border-translucent">
        <thead>
          <tr>
            <th scope="col" style={{ width: 330 }}>
              Módulo
            </th>
            <th className="ps-5 text-center" scope="col" style={{ width: 30 }}>
              Habilitado
            </th>
          </tr>
        </thead>
        <tbody className="list" id="cart-table-body">
          {products.map((product) => {
            // Verifica se o módulo está selecionado (ativado)
            const isSelected = selectedProducts.some(
              (p) => p.id === product.id
            );

            return (
              <AssinaturaTableRow
                product={product}
                key={product.id}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  e.target.checked
                    ? addProduct(product)
                    : removeProduct(product)
                }
                isEnabled={isSelected} // Passa o estado do módulo (habilitado ou não)
              />
            );
          })}
        </tbody>
      </Table>
    </Scrollbar>
  );
};

const AssinaturaTableRow = ({
  product,
  onChange,
  isEnabled,
}: {
  product: ModuloItemType;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  isEnabled: boolean;
}) => {
  return (
    <tr className="cart-table-row" key={product.id}>
      <td>
        <Link className="fw-semibold line-clamp-2" to="#!">
          {product.descricao}
        </Link>
      </td>
      <td className="fs-8 ps-5 text-center">
        <Form.Check type="switch" checked={isEnabled} onChange={onChange} />
      </td>
    </tr>
  );
};

export default AssinaturaTable;
