import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import OrderInfoItem from "./OrderInfoItem";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { currencyFormat } from "helpers/utils";

const fetchEntradaDeNota = async ({ queryKey }: any) => {
  const [_, id] = queryKey;
  const { data } = await axios.get(`/api/entradaDeNota/${id}`);

  return data;
};

export const NotaDetails = () => {
  const { id } = useParams();
  const query = useQuery({
    queryKey: ["entradaDeNota", id],
    queryFn: fetchEntradaDeNota,
  });

  if (query.isLoading) return null;

  const { fornecedor, fornecedorId, nnf, dhEmi, vNF } = query.data;
  const { razaoSocial, cnpj, telefone } = fornecedor;
  return (
    <>
      <h4 className="mb-5">Detalhes da nota</h4>
      <Row className="g-4 flex-sm-column">
        <Col xs={6} sm={12}>
          <OrderInfoItem icon="user" label="Fornecedor" />
          <Link
            className="fs-9 ms-4"
            to={`/retaguarda/cadastros/fornecedores/${fornecedorId}`}
          >
            {razaoSocial}
          </Link>
        </Col>
        <Col xs={6} sm={12}>
          <OrderInfoItem icon="file" label="Número" />
          <div className="ms-4">
            <p className="text-body-secondary mb-0 fs-9">{nnf}</p>
          </div>
        </Col>
        <Col xs={6} sm={12}>
          <OrderInfoItem icon="calendar" label="Emissão" />
          <div className="ms-4">
            <p className="text-body-secondary mb-0 fs-9">
              {moment(dhEmi).format("DD/MM/YYYY")}
            </p>
          </div>
        </Col>
        <Col xs={6} sm={12}>
          <OrderInfoItem icon="file-text" label="Total" />
          <div className="ms-4">
            <p className="text-body-secondary mb-0 fs-9">
              {currencyFormat(vNF)}
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export const ICMS = () => {
  return (
    <>
      <h4 className="mb-5">ICMS</h4>
      <Row className="g-4 flex-sm-column">
        <Col xs={6} sm={12}>
          <OrderInfoItem icon="mail" label="Email" />
          <Link className="fs-9 ms-4" to="mailto:shatinon@jeemail.com">
            shatinon@jeemail.com
          </Link>
        </Col>
        <Col xs={6} sm={12}>
          <OrderInfoItem icon="phone" label="Phone" />
          <Link className="fs-9 ms-4" to="tel:+1234567890">
            +1234567890
          </Link>
        </Col>
        <Col xs={6} sm={12}>
          <OrderInfoItem icon="calendar" label="Shipping Date" />
          <p className="mb-0 text-body-secondary fs-9 ms-4">12 Nov, 2021</p>
        </Col>
        <Col xs={6} sm={12}>
          <OrderInfoItem icon="home" label="Address" />
          <div className="ms-4">
            <p className="text-body-secondary mb-0 fs-9">Shatinon Mekalan</p>
            <p className="text-body-secondary mb-0 fs-9">
              Vancouver, British Columbia,
              <br className="d-none d-sm-block" />
              Canada
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export const OtherDetails = () => {
  return (
    <>
      <h4 className="mb-5">Other details</h4>
      <Row className="g-4 flex-sm-column">
        <Col xs={6} sm={12}>
          <OrderInfoItem icon="shopping-bag" label="Gift order" />
          <p className="mb-0 text-body-secondary fs-9 ms-4">Yes</p>
        </Col>
        <Col xs={6} sm={12}>
          <OrderInfoItem icon="package" label="Wraping" />
          <p className="mb-0 text-body-secondary fs-9 ms-4">Magic wrapper</p>
        </Col>
        <Col xs={6} sm={12}>
          <OrderInfoItem icon="file-text" label="Recipient" />
          <p className="mb-0 text-body-secondary fs-9 ms-4">Recipient</p>
        </Col>
        <Col xs={6} sm={12}>
          <OrderInfoItem icon="mail" label="Gift Meassge" />
          <div className="ms-4">
            <p className="text-body-secondary fs-9 mb-0">
              Happy Birthday Shiniga <br />
              Lots of Love Buga Buga!!
            </p>
            <p className="mb-0 text-body-secondary fs-9">
              Yours, <br />
              Mekalan
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};
