import { PageBreadcrumbItem } from "components/common/PageBreadcrumb";

export type ModuloItemType = {
  id: number | string;
  descricao: string;
  isEnabled: boolean;
};

export const assinaturaBreadcrumbItems = (
  cnpj?: string
): PageBreadcrumbItem[] => [
  {
    label: "Revenda",
    url: "#!",
  },
  {
    label: "Clientes",
    url: "/clientes",
  },
  {
    label: cnpj ?? "Novo",
    url: cnpj ? `/clientes/${cnpj}` : "#!",
  },
  {
    label: "Assinatura",
    active: true,
  },
];
