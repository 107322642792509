import { faFileImport, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  Updater,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import Button from "components/base/Button";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import SearchBox from "components/common/SearchBox";
import ProdutoTable, {
  produtoTableColumns,
} from "components/tables/ProdutoTable";
import { produtosBreadcrumbItems } from "data/produto";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { ChangeEvent, useState } from "react";
import { Modal, Placeholder, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import useDebounce from "hooks/useDebounce";
import FilterTab, { FilterTabItem } from "components/common/FilterTab";
import Menus from "./menus";
import { useConfirmation } from "hooks/useConfirmation";
import Dropzone from "components/base/Dropzone";
import { Buffer } from "buffer";
import ImportModal from "./importModal";

type Filter = { [key: string]: any };

const fetchProdutos = async ({
  pageIndex,
  pageSize,
  filter,
  sort,
  tabFilter,
  menuFilter,
}: {
  pageIndex: number;
  pageSize: number;
  filter?: string;
  sort?: SortingState;
  tabFilter?: string;
  menuFilter?: Filter;
}) => {
  const { data } = await axios.get("/api/produto", {
    params: {
      pageSize,
      pageIndex,
      filter,
      sort:
        sort && sort.length > 0
          ? `${sort[0].id},${sort[0].desc ? "desc" : "asc"}`
          : undefined,
      tabFilter,
      ...menuFilter,
    },
  });
  return data;
};

const fetchProdutosResumo = async ({ menuFilter }: { menuFilter: Filter }) => {
  const { data } = await axios.get("/api/produto/resumo", {
    params: menuFilter,
  });
  return data;
};

const Produtos = () => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const importConfirm = useConfirmation();

  const [sort, setSort] = useState<SortingState>(!keepPreviousData ? [] : []);
  const [filter, setFilter] = useState<string | undefined>();
  const [tabFilter, setTabFilter] = useState<string | undefined>();
  const [menuFilter, setMenuFilter] = useState<Filter>({});
  const debouncedFilter = useDebounce(filter, 300);

  const tabsQuery = useQuery<FilterTabItem[]>({
    queryKey: ["produtosResumo", menuFilter],
    queryFn: () => fetchProdutosResumo({ menuFilter }),
    placeholderData: keepPreviousData,
  });

  const tabs =
    tabsQuery.data?.map((t) => {
      return { ...t, onClick: () => setTabFilter(t.value) };
    }) ?? [];

  const dataQuery = useQuery({
    queryKey: [
      "produtos",
      pagination,
      debouncedFilter,
      sort,
      tabFilter,
      menuFilter,
    ],
    queryFn: () =>
      fetchProdutos({
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        filter,
        sort,
        tabFilter,
        menuFilter,
      }),
    placeholderData: keepPreviousData, // don't have 0 rows flash while changing pages/loading next page
  });

  const table = useReactTable({
    data: dataQuery.data?.itens ?? [],
    columns: produtoTableColumns,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    onGlobalFilterChange: setFilter,
    manualPagination: true,
    manualFiltering: true,
    manualSorting: true,
    onSortingChange: (updateOrValue: Updater<SortingState>) => {
      if (typeof updateOrValue === "function") {
        //intercepta a função de atualização do estado
        setSort((prev) => {
          const updated = updateOrValue(prev);
          //se for o mesmo id do state, inverte o desc
          if (prev[0]?.id === updated[0]?.id) {
            updated[0].desc = !prev[0]?.desc;
          }
          return updated;
        });
      } else {
        console.log(updateOrValue);
        setSort(updateOrValue);
      }
    },
    pageCount: dataQuery.data?.totalPages ?? 0,
    state: {
      globalFilter: "",
      pagination: {
        pageIndex: dataQuery.data?.pageIndex ?? 0,
        pageSize: dataQuery.data?.pageSize ?? 10,
      },
    },
    initialState: {
      pagination: {
        pageIndex: dataQuery.data?.pageIndex ?? 0,
        pageSize: dataQuery.data?.pageSize ?? 10,
      },
    },
  });

  if (dataQuery.isLoading)
    return (
      <Spinner animation="border" role="status" className="d-block mx-auto">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const handleImportar = async () => {
    try {
      await importConfirm.requestConfirmation();
      dataQuery.refetch();
    } catch (error) {}
  };

  return (
    <div>
      <ImportModal {...importConfirm} />
      <PageBreadcrumb items={produtosBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">Produtos</h2>
        {tabsQuery.isLoading ? (
          <Placeholder animation="wave" as="div" className="mb-2">
            <Placeholder xs={3} />
          </Placeholder>
        ) : (
          <FilterTab tabItems={tabs} className="mb-2" />
        )}

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox
                placeholder="Pesquisar produtos"
                onChange={handleSearchInputChange}
              />
              <Menus filters={menuFilter} onFilter={setMenuFilter} />
              <div className="ms-xxl-auto">
                <Button
                  variant="link"
                  className="text-body me-4 px-0"
                  onClick={handleImportar}
                >
                  <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />
                  Importar
                </Button>
                <Button
                  variant="primary"
                  as={Link}
                  to="/retaguarda/cadastros/produtos/novo"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Cadastrar
                </Button>
              </div>
            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <ProdutoTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Produtos;
