import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import SignInForm from "components/modules/auth/SignInForm";
import AuthSimpleLayout from "layouts/AuthSimpleLayout";
import { useAuth } from "providers/AuthProvider";
import { Alert, Form } from "react-bootstrap";
import { Navigate, useSearchParams } from "react-router-dom";

const postLogin = async (data: any) => {
  return await axios.post("/api/user/login", data);
};

const SignIn = () => {
  const { setNewPassword, setUser } = useAuth();
  const [searchParams] = useSearchParams();
  const { isPending, mutate, error, data } = useMutation<
    {
      data: {
        status: number;
        session: string;
        email: string;
        user: { email: string; name: string };
      };
    },
    any,
    { email: string; password: string }
  >({
    mutationFn: postLogin,
  });

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await mutate({
      email: e.currentTarget.email.value,
      password: e.currentTarget.password.value,
    });
  };

  if (data?.data?.status === 2) {
    console.log(data);
    setNewPassword(data.data.email, data.data.session);
    return (
      <Navigate
        to="/pages/authentication/simple/reset-password"
        replace
        state={{
          email: data.data.email,
          session: data.data.session,
        }}
      />
    );
  }

  if (data?.data?.status === 1) {
    return (
      <Navigate
        to="/pages/authentication/simple/2FA"
        replace
        state={{ email: data.data.email, recovery: false }}
      />
    );
  }

  if (data?.data?.user) {
    setUser(data.data.user);
    const from = searchParams.get("from");
    return <Navigate to={from || "/"} replace />;
  }

  return (
    <AuthSimpleLayout>
      <Form onSubmit={handleLogin}>
        <SignInForm layout="simple" loading={isPending} />
        {error && (
          <Alert variant={"phoenix-danger"} className="mt-4">
            {error?.response?.data?.message || "Ocorreu um erro inesperado."}
          </Alert>
        )}
      </Form>
    </AuthSimpleLayout>
  );
};

export default SignIn;
