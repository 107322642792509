import { PageBreadcrumbItem } from "components/common/PageBreadcrumb";

export type PrincipioAtivoType = {
  id: number;
  codigo: number;
  descricao: string;
};

export type CriarPrincipioAtivoDtoType = {
  descricao: string;
};

export type AlterarPrincipioAtivoDtoType = {
  codigo: number;
  descricao: string;
};

export type PrincipioAtivoTableType = {
  id: number;
  codigo: number;
  descricao: string;
};

export const principiosAtivosBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: "Retaguarda",
    url: "#!",
  },
  {
    label: "Cadastros",
    url: "#!",
  },
  {
    label: "Princípios Ativos",
    active: true,
  },
];

export const principioAtivoBreadcrumbItems = (
  codigo?: number
): PageBreadcrumbItem[] => [
  {
    label: "Retaguarda",
    url: "#!",
  },
  {
    label: "Cadastros",
    url: "#!",
  },
  {
    label: "Princípio Ativo",
    url: "/retaguarda/cadastros/principios-ativos",
  },
  {
    label: codigo?.toString() ?? "Novo",
    active: true,
  },
];
