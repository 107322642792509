import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, ReactElement } from "react";
import Select from "react-select/async-creatable";

import { CreatableAdditionalProps } from "react-select/dist/declarations/src/useCreatable";

import {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
} from "react-select";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";
import { MenuList } from "./ReactSelect";

import { AsyncProps } from "react-select/async";

const ClearIndicator = (props: ClearIndicatorProps) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref} className="me-2">
      <div className="text-primary fs-9">limpar</div>
    </div>
  );
};

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faAngleDown} className="fs-9 text-body" />
    </components.DropdownIndicator>
  );
};

const AsyncReactSelect = forwardRef(
  (
    {
      icon,
      ...rest
    }: StateManagerProps &
      AsyncProps<any, any, any> &
      CreatableAdditionalProps<any, any> & { icon?: ReactElement },
    ref
  ) => {
    return (
      <div className="react-select-container">
        <Select
          ref={ref as any}
          closeMenuOnSelect={true}
          createOptionPosition="first"
          formatCreateLabel={(inputValue) => `Criar "${inputValue}"`}
          components={{
            ClearIndicator,
            DropdownIndicator,
            MenuList,
          }}
          classNamePrefix="react-select"
          classNames={{
            control: () => (icon ? "ps-5" : ""),
            placeholder: () => (icon ? "ps-2" : ""),
          }}
          {...rest}
        />
        {icon}
      </div>
    );
  }
);

export default AsyncReactSelect;
