import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import Button from "components/base/Button";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import { implantacaoBreadcrumbItems } from "data/implantacao";
import { useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";

function Implantacao() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const mutation = useMutation<any>({
    mutationFn: (payload) => {
      return axios.post(`/api/hubs/implantacao`, payload);
    },
  });

  const { connId } = useParams();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const form = event.currentTarget as HTMLFormElement;
      const cnpj = form.cnpj.value;
      const data = {
        cnpj: cnpj.replace(/\D/g, ""),
        connectionId: connId,
      };

      const response = await mutation.mutateAsync({
        ...data,
      } as any);

      console.log(response);

      if (response.status === 200) {
        setSuccess("Liberação realizada com sucesso");
      }
    } catch (error: any) {
      console.log(error);
      setError(error?.response?.data ?? error.message ?? "Erro desconhecido");
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };
  return (
    <div>
      <PageBreadcrumb items={implantacaoBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">Implantar</h2>
        {error && <Alert variant={"phoenix-danger"}>{error}</Alert>}
        {success && <Alert variant={"phoenix-success"}>{success}</Alert>}
        <div className="mt-3 d-flex w-100 justify-content-center">
          <form className="w-50" onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="cnpj">
              <Form.Label htmlFor="cnpj">CNPJ</Form.Label>
              <Form.Control id="cnpj" name="cnpj" />
            </Form.Group>
            <Button
              className="btn btn-primary w-100"
              type="submit"
              loading={mutation.isPending}
              loadingPosition="start"
            >
              Liberar
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Implantacao;
