import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Button from "components/base/Button";
import ReactSelect from "components/base/ReactSelect";
import { EmpresaQueryType, EmpresaType } from "data/empresa";
import { EditarProdutoDtoType } from "data/produto";
import { Card, Col, Row } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";

const fetchEmpresas = async () => {
  const { data } = await axios.get("/api/empresa");
  return data;
};

const fetchRegrasFiscais = async () => {
  const { data } = await axios.get("/api/regraFiscal", {
    params: { pageIndex: 0, pageSize: 9999999 },
  });

  return data;
};

const RegraFiscalEmpresaFormCard = ({ className }: { className?: string }) => {
  const empresasQuery = useQuery<EmpresaQueryType>({
    queryKey: ["empresas"],
    queryFn: fetchEmpresas,
  });

  const regrasFiscaisQuery = useQuery({
    queryKey: ["regrasFiscais"],
    queryFn: fetchRegrasFiscais,
  });

  const { control, watch, setValue } = useFormContext<EditarProdutoDtoType>();

  const codigo = watch("codigo");
  const descricao = watch("descricao");
  const ean = watch("codigosDeBarras");

  const sanearMutation = useMutation({
    mutationFn: () => {
      return axios.get("/api/produto/sanear", {
        params: { codigo, descricao, ean: ean[0] },
      });
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "empresas",
    rules: { required: true },
  });

  const handleSanear = async () => {
    var r = await sanearMutation.mutateAsync();

    regrasFiscaisQuery.refetch();

    r.data.forEach((empresaFiscal: any) => {
      const empresaIndex = fields.findIndex(
        (field) => field.idEmpresa === empresaFiscal.idEmpresa
      );

      console.log(empresaFiscal, empresaIndex);

      if (empresaIndex !== -1) {
        setValue(
          `empresas.${empresaIndex}.idRegraFiscal`,
          empresaFiscal.idRegraFiscal
        );
      }
    });
  };

  return (
    <Card className={className}>
      <Card.Body>
        <h4 className="mb-4">Regras Fiscais</h4>

        {empresasQuery.isSuccess && empresasQuery.data.itens.length > 0 && (
          <Row className="gx-3 gy-4 mb-3">
            {empresasQuery.data?.itens?.map(
              (empresa: EmpresaType, index: number) => {
                const id = watch(`empresas.${index}.idRegraFiscal`);
                const regraFiscal = regrasFiscaisQuery.data?.itens?.find(
                  (regraFiscal: any) => regraFiscal.id === id
                );
                return (
                  <Col key={fields[index]?.id} xs={12} sm={6} xl={12}>
                    <div className="border-bottom border-translucent border-dashed border-sm-0 border-bottom-xl pb-4">
                      <div className="d-flex gap-2 mb-2">
                        <h5 className="mb-0 text-body-highlight">
                          {empresa.nomeFantasia}
                        </h5>
                        <Link className="fw-bold fs-9" to="#!">
                          Visualizar
                        </Link>
                      </div>
                      <ReactSelect
                        options={regrasFiscaisQuery.data?.itens?.map(
                          (regraFiscal: any) => ({
                            label: `${regraFiscal.codigo} - ${regraFiscal.descricao}`,
                            value: regraFiscal.id,
                          })
                        )}
                        onChange={(selectedOption: any) =>
                          setValue(
                            `empresas.${index}.idRegraFiscal`,
                            selectedOption?.value
                          )
                        }
                        value={
                          regraFiscal && {
                            label: `${regraFiscal.codigo} - ${regraFiscal.descricao}`,
                            value: regraFiscal.id,
                          }
                        }
                        placeholder="Selecione uma regra fiscal"
                      />
                    </div>
                  </Col>
                );
              }
            )}
          </Row>
        )}
        <Button
          variant="phoenix-primary"
          className="w-100"
          onClick={handleSanear}
          loading={sanearMutation.isPending || regrasFiscaisQuery.isFetching}
          loadingPosition="start"
        >
          Sanear
        </Button>
      </Card.Body>
    </Card>
  );
};

export default RegraFiscalEmpresaFormCard;
