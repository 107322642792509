import { Card, Col, Form, Row } from "react-bootstrap";
import Dropzone from "components/base/Dropzone";
import { Buffer } from "buffer";
import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import axios from "axios";
import Button from "components/base/Button";
import Badge from "components/base/Badge";
import moment from "moment";

const EditarCertificadoFormCard = ({ className }: { className?: string }) => {
  const { id } = useParams();
  const { data, isLoading, isError, refetch } = useQuery<{
    data: { expiracao: string };
  }>({
    queryKey: ["certificado", id],
    queryFn: () => axios.get(`/api/empresa/${id}/certificado`),
    enabled: !!id,
  });
  const [cert, setCert] = useState({
    arquivo: "",
    senha: "",
  });

  const mutation = useMutation<
    any,
    any,
    { id: number; data: { arquivo: string; senha: string } }
  >({
    mutationFn: ({ id, data }) =>
      axios.put(`/api/empresa/${id}/certificado`, data),
    onSuccess: () => {
      refetch();
      setCert({ arquivo: "", senha: "" });
    },
  });

  if (isError) {
    return <div>Erro ao buscar certificado</div>;
  }

  return (
    <Card className={className}>
      <Card.Body>
        <Form noValidate>
          <h4 className="mb-4">Certificado Digital</h4>
          <Row className="gx-3 gy-4">
            <Col xs={12} sm={6} xl={12}>
              <div className="d-flex gap-2 mb-2">
                <h5 className="mb-0 text-body-highlight">Validade</h5>
              </div>
              {moment(data?.data?.expiracao).isBefore(moment()) ? (
                <Badge variant="default" bg="danger" className="mb-2">
                  Expirado
                </Badge>
              ) : (
                <Badge variant="default" className="mb-2">
                  {data?.data?.expiracao
                    ? moment(data?.data?.expiracao).format("DD/MM/YYYY")
                    : "-"}
                </Badge>
              )}
            </Col>
            <Col xs={12} sm={6} xl={12}>
              <div className="d-flex gap-2 mb-2">
                <h5 className="mb-0 text-body-highlight">A1</h5>
              </div>
              <Dropzone
                onDrop={async (acceptedFiles) => {
                  const file = acceptedFiles[0];

                  const base64 = await file.arrayBuffer().then((buffer) => {
                    const b = Buffer.from(buffer);
                    return b.toString("base64");
                  });

                  setCert({ ...cert, arquivo: base64 });
                }}
                accept={{
                  "application/x-pkcs12": [".p12", ".pfx"],
                }}
              />
            </Col>
            <Col xs={12} sm={6} xl={12}>
              <div className="d-flex gap-2 mb-2">
                <h5 className="mb-0 text-body-highlight">Senha</h5>
              </div>
              <Form.Control
                type="password"
                placeholder="Senha do certificado"
                value={cert.senha}
                onChange={(e) => setCert({ ...cert, senha: e.target.value })}
              />
            </Col>
          </Row>

          <Button
            variant="phoenix-primary"
            className="w-100 mt-5"
            loading={mutation.isPending}
            disabled={mutation.isPending}
            onClick={() => {
              mutation.mutate({ id: Number(id), data: cert });
            }}
            loadingPosition="start"
          >
            Salvar
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default EditarCertificadoFormCard;
