import { PageBreadcrumbItem } from "components/common/PageBreadcrumb";

export type Cliente = {
  id: number;
  codigo: number;
  razaoSocial: string;
  cnpj: string;
};

export type ClienteTableType = {
  id: number | undefined;
  codigo: number | undefined;
  nomeFantasia: string;
  razaoSocial: string;
  cnpj: string;
  inscricaoEstadual: string;
};

export const clientesBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: "Revenda",
    url: "#!",
  },
  {
    label: "Clientes",
    active: true,
  },
];

export const clienteBreadcrumbItems = (cnpj?: string): PageBreadcrumbItem[] => [
  {
    label: "Revenda",
    url: "#!",
  },
  {
    label: "Clientes",
    url: "/clientes",
  },
  {
    label: cnpj ?? "Novo",
    active: true,
  },
];
