import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Button from "components/base/Button";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import {
  AlterarFabricanteDtoType,
  fabricanteBreadcrumbItems,
} from "data/fabricantes";
import {
  AlterarPrincipioAtivoDtoType,
  principioAtivoBreadcrumbItems,
} from "data/principiosAtivos";

import { transformErrors } from "helpers/utils";
import { useEffect } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";

const EditarPrincipioAtivo = () => {
  const { id } = useParams();

  const { isLoading, isError, data } = useQuery<{
    data: AlterarPrincipioAtivoDtoType;
  }>({
    queryKey: ["principioAtivo", id],
    queryFn: () => axios.get(`/api/principioAtivo/${id}`),
    enabled: !!id,
  });

  const mutation = useMutation<
    any,
    any,
    { id: number; data: AlterarPrincipioAtivoDtoType }
  >({
    mutationFn: ({ id, data }) => axios.put(`/api/principioAtivo/${id}`, data),
    onError: (error) => {
      const formattedErrors = transformErrors(error.response.data.errors);
      for (const [field, error] of Object.entries(formattedErrors)) {
        form.setError(field as any, error as any);
      }
    },
  });

  const principioAtivo = data?.data;

  const form = useForm<AlterarPrincipioAtivoDtoType>();

  useEffect(() => {
    if (principioAtivo) {
      console.log(principioAtivo);
      form.reset(principioAtivo);
    }
  }, [principioAtivo]);

  if (isLoading)
    return (
      <Spinner animation="border" role="status" className="d-block mx-auto">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );

  if (isError) {
    return <div>Ocorreu um erro ao carregar o princípio ativo</div>;
  }

  if (!principioAtivo) {
    return <div>Princípio ativo não encontrado</div>;
  }

  const onSubmit: SubmitHandler<AlterarPrincipioAtivoDtoType> = (data) => {
    mutation.mutate({ data, id: parseInt(id as string) });
  };

  if (mutation.isSuccess) {
    return <Navigate to={`/retaguarda/cadastros/principios-ativos`} />;
  }

  return (
    <div>
      <FormProvider {...form}>
        <PageBreadcrumb
          items={principioAtivoBreadcrumbItems(principioAtivo?.codigo)}
        />
        <Form
          noValidate
          className="mb-9"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
            <div>
              <h2 className="mb-2">Editar um princípio ativo</h2>
              <h5 className="text-body-tertiary fw-semibold">
                Atualize as informações do princípio ativo
              </h5>
            </div>
            <div className="d-flex flex-wrap gap-2">
              <Button
                variant="phoenix-secondary"
                type="button"
                onClick={() => form.reset()}
              >
                Descartar
              </Button>
              <Button
                variant="primary"
                type="submit"
                loading={mutation.isPending}
                disabled={mutation.isPending}
                loadingPosition="start"
              >
                Salvar
              </Button>
            </div>
          </div>
          <Row className="g-5">
            <Col xs={12} xl={8}>
              <h4 className="mb-3">Informações do princípio ativo</h4>
              <Row className="g-2">
                <Form.Group
                  as={Col}
                  xs={2}
                  xl={2}
                  className="mb-5"
                  controlId="codigo"
                >
                  <Form.Control
                    disabled
                    value={principioAtivo.codigo}
                    required
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={8}
                  xl={10}
                  className="mb-5"
                  controlId="descricao"
                >
                  <Form.Control
                    placeholder="Descrição do princípio ativo"
                    {...form.register("descricao")}
                    required
                    isInvalid={!!form.formState.errors.descricao}
                  />
                  <Form.Control.Feedback type="invalid">
                    {form.formState.errors.descricao?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </div>
  );
};

export default EditarPrincipioAtivo;
